import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useStateContext } from "../../context/StateContext";
import StoreToken from "../../components/StoreToken";
import { globalLoader, defaultQuality, get_platform_bg_color, getAuthToken, catchError, getMobileOperatingSystem, getWhatsappShareUrl, 
         get_platform_action_color, getApiUrlFromSelectionType, token_url, isAdmin, checkPlurality, get_more_logo, getAPIUrl, handleLibrary, 
         navigateToUrl, setNavigationHistory, getAllChildClassNames } from "../../script/util";
import axios, { all } from "axios";
import './WorkDetails.css'
import Masonry from "react-masonry-css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faPlay, faXmark, faArrowUp, faArrowRight, 
         faPlus, faChevronLeft, faArrowLeft, 
         faCircleCheck, faRotateRight, 
         faAngleLeft, faAngleRight,
         faCopy, faExternalLinkAlt,
         faCircleInfo, faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import VideoPlayer from "../../components/VideoPlayer.js";
import AlbumView from "../../components/AlbumView/AlbumView.js";
import homeIcon from '../../images/home.png'
import Navbar from '../../components/Navbar/Navbar.jsx'
import SkeletonLoader from "../../components/SkeletonLoader.js";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import videoIcon from '../../images/video.png'
import imageIcon from '../../images/camera.png'
import albumIcon from '../../images/drawing-book.png'
import selfieIcon from '../../images/face-scan.png'
import hideIcon from '../../images/hide.png'
import whiteHideIcon from '../../images/Hide-Button-White.png'
import redHideIcon from '../../images/Hide-Button-Red.png'
import heartIcon from '../../images/heart.png'
import redHeartIcon from '../../images/redHeart.png'
import shareIcon from '../../images/share.png'
import albumSelectionIcon from '../../images/albumSelection.png'
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import Toast from "../../components/Toast/Toast.js";
import copy from "clipboard-copy";
import { selectionModeArray } from '../../script/SelectionMode.js'
import { faCircleCheck as hollowCheck } from "@fortawesome/free-regular-svg-icons";
import noPhotoDog from '../../images/noPhoto.png'
import filter from '../../images/sort.png'
import FaceSearch from "../../components/FaceSearch/FaceSearch.js";
import Popup from "../../components/Popup/Popup.js";
import downloadIcon from '../../images/download.png'
import lockIcon from'../../images/lock.png'
import checkIcon from '../../images/checked.png'
import pencilIcon from '../../images/pencil.png'
import deleteIcon from '../../images/delete.png'
import uploadIcon from '../../images/upload.png'
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import GuestUpload from "../GuestUpload/GuestUpload.js";
import allUploadIcon from '../../images/allUploads.png'
import bookingIcon from "../../images/bookings.png";
import myUploadIcon from '../../images/myUpload.png'
import libraryIcon from '../../images/libraryIcon.png'
import linkIcon from '../../images/link.png'
import { GiHamburgerMenu } from 'react-icons/gi'
import { get_can_add_to, get_can_delete, get_can_make_private, get_global_can_add_to, get_global_can_make_private, 
         get_global_is_album_required, get_global_is_downloadable, get_is_album_required, get_is_downloadable, 
         get_liked_data_is_downloadable, 
         get_selected_workId,
         get_user_liked_photos,
         getApiMethod,
         getCurrentPage,
         sort_data_by_is_downloadable} from "./work_utils.js";
import FloatingBar from "../../components/FloatingBar/FloatingBar.js";
import CustomeError from "../../components/CustomError/CustomError.jsx";
import * as CONST from '../../script/const.js'

const WorkDetail = () => {
    const { isLoggedin } = useStateContext();
    const { storeToLocal } = StoreToken();
    const numImages = 1000
    const [data, setData] = useState();
    const [collection, setCollection] = useState(null);
    const [bookingPK, setBookingPK] = useState()
    const [videoUrl, setVideoUrl] = useState()
    const defQuality = defaultQuality()
    const [scrollPosition, setScrollPosition] = useState(0);
    const [galleryImages, setGalleryImages] = useState()
    const [hdUrl, setHdUrl] = useState(false) 
    const [showTooltip, setShowTooltip] = useState(false);
    const [albumView, setAlbumView] = useState(false)
    const [videoView, setVideoView] = useState(false)
    const [showToast, setShowToast] = useState(false)
    const [reloadDelete, setReloadDelete] = useState(false)
    const [deleteConfirm, setDeleteConfirm] = useState(false)
    const [deletePopup, setDeletePopup] = useState(false)
    const [toastMessage, setToastMessage] = useState()
    const [selectionType, setSelectionType] = useState()
    const [images, setImages] = useState();
    const [loading, setLoading] = useState(false);
    const [updateLoader, setUpdateLoader] = useState(false)
    const [allGuestPhotos, setAllGuestPhotos] = useState(true);
    const [yourUploads, setYourUploads] = useState();
    const [filterDropdown, setFilterDropdown] = useState(false)
    const [selectedOption, setSelectedOption] = useState('showall')
    const [loadingHD, setLoadingHD] = useState(false);
    const [hiddenWork, setHiddenWork] = useState({});
    const [shareFaceFilter, setShareFaceFilter] = useState(true)
    const [nextCollection, setNextCollection] = useState(false)
    const [nextCollectionName, setNextCollectionName] = useState(null)
    const [prevCollection, setPrevCollection] = useState()
    const [selectionAll, setSelectionAll] = useState({})
    const [photoScroll, setPhotoScroll] = useState(0)
    const [fullScreen, setFullScreen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState();
    const [indexDisplay, setIndexDisplay] = useState(1);
    const [collectionData, setCollectionData] = useState()
    const [height, setHeight] = useState(window.innerHeight);
    const [prevScrollPosition, setPrevScrollPosition] = useState(0);
    const [showFilters, setShowFilters] = useState(true)
    const [showArrow, setShowArrow] = useState(false)
    const [hideSelection, setHideSelection] = useState(false)
    const [downloadSelection, setDownloadSelection] = useState(false)
    const [deleteSelection, setDeleteSelection] = useState(false)
    const [addToSelection, setAddToSelection] = useState(false)
    const [isAddToOpen, setIsAddToOpen] = useState(false)
    const [isSingleAddToOpen, setIsSingleAddToOpen] = useState(false)
    const [selection, setSelection] = useState(false)
    const [selectedImages, setSelectedImages] = useState({});
    const [classObj, setClassObj] = useState()
    const [hideSelectedObj, setHideSelectedObj] = useState({})
    const [showGallery, setShowGallery] = useState(false);
    const [gridFirstCheck, setGridFirstCheck] = useState(true)
    const [faceFilter, setFaceFilter] = useState(false)
    const [faceSearchState, setFaceSearchState] = useState(false)
    const [faceCollection, setFaceCollection] = useState()
    const [faceSearchData, setFaceSearchData] = useState()
    const [faceSearchOn, setFaceSearchOn] = useState(false)
    const [pastFaceSearches, setPastFaceSearches] = useState()
    const [croppedImages, setCroppedImages] = useState({});
    const [isScrollable, setIsScrollable] = useState(false)
    const [contentLoading, setContentLoading] = useState(false)
    const [faceFormattedData, setFaceFormattedData] = useState({})
    const [collectionFormattedData, setCollectionFormattedData] = useState({})
    const [faceOnly, setFaceOnly] = useState(false)
    const [faceSelect, setFaceSelect] = useState({})
    const [faceHide, setFaceHide] = useState({})
    const [multipleSelect, setMultipleSelect] = useState([])
    const [updateSinglePhoto, setUpdateSinglePhoto] = useState(false)
    const [filteredData, setFilteredData] = useState(false)
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const [downloadDetails, setDownloadDetails] = useState()
    const [selectImages, setSelectImages] = useState({})
    const [albumData, setAlbumData] = useState()
    const [defAlbumData, setDefAlbumData] = useState()
    const [singleAlbumData, setSingleAlbumData] = useState()
    const [shareDropdown, setShareDropdown] = useState(false)
    const [moreDropdown, setMoreDropdown] = useState(false)
    const [shareLinkData, setShareLinkData] = useState()
    const [tncState, setTncState] = useState(false)
    const [shareLinkPopup, setShareLinkPopup] = useState(false)
    const [shareSelective, setShareSelective] = useState(false)
    const [shareSelectList, setShareSelectList] = useState({})
    const [shareTab, setShareTab] = useState('photos')
    const [distanceFromTop, setDistanceFromTop] = useState(0);
    const [recallCollection, setRecallCollection] = useState(false)
    const [loadedImages, setLoadedImages] = useState({});
    const [currentDimensions, setCurrentDimensions] = useState(window?.innerWidth)
    const [pillMargin, setPillMargin] = useState(15)
    const [pastMargin, setPastMargin] = useState(15)
    const [downloadPopup, setDownloadPopup] = useState(false)
    const [qualityVideo, setQualityVideo] = useState(false)
    const [videoDetails, setVideoDetails] = useState()
    const [currentQuality, setCurrentQuality] = useState(1080)
    const [currentQualityUrl, setCurrentQualityUrl] = useState()
    const [readyFaceSearchDownload, setReadyFaceSearchDownload] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [albumTitle, setAlbumTitle] = useState()
    const [albumLocked, setAlbumLocked] = useState(0)
    const [lockPopup, setLockPopup] = useState(false)
    const [likeData, setLikeData] = useState()
    const [initialCarousel, setInitialCarousel] = useState(true)
    const [likeViewPopup, setLikeViewPopup] = useState(false)
    const [workIdError, setWorkIdError] = useState(false);
    const [workIdErrorMessage, setWorkIdErrorMessage] = useState("Something went wrong.");
    const [nickname, setNickname] = useState("");
    const [userFolderData, setUserFolderData] = useState()
    const [redirectUrl, setRedirectUrl] = useState([])
    const photos = 'photos'
    const guestUpload = 'guestupload'
    const albumSelection = 'albumselection'
    const userFolders = 'userfolders'
    const videos = 'videos'
    const albums = 'albums'
    const hide = 'hide'
    const download = 'download'
    const deleteUploads = 'delete_guest_gallery'
    const likePhotos = 'like_photos'
    const viewPhotos = 'view_photos'
    const addTo = 'addTo'
    const allPhotos = 'showall'
    const privatePhotos = 'private'
    const publicPhotos = 'public'
    const thumbRef = useRef();
    const moreRef = useRef();
    const likeViewRef = useRef(null);
    const scrollContainerRef = useRef(null);
    const thumbState = useRef(false);
    const carouselRef = useRef(null);
    const storyRef = useRef(null)
    const dropdownRef = useRef(null)
    const shareRef = useRef(null)
    const zoomRef = useRef(window.devicePixelRatio);
    const divRef = useRef(null);
    const popupRef = useRef(null)
    const firstCheck = useRef(true)
    const firstCheckMedia = useRef(true)
    const viewTimeoutRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const nicknameInput = useRef(null);
    useLayoutEffect(() => {
        storeToLocal();
    }, []);
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("id");
    const for_passkey = queryParams.get("for_passkey")
    const shared_key = queryParams.get("key")
    const tab = queryParams.get("tab")
    let currentPage = getCurrentPage(CONST, tab);
    
    if((!id || id=='null') && !shared_key && tab!==userFolders){
        navigate('/')
    }
    const collection_id = queryParams.get("work_id")
    const albumStatus = queryParams.get('album_status')
    const videoStatus = queryParams.get('video_status')
    var faceSearchOnly = queryParams.get('face_only') === 'true'
    var currentIndex = 0;
    var currentId = 0;
    var storedCollectionData = {};
    const breakpointColumnsObj = {
        default: 4,
        1300: 4,
        800: 3,
        600: 2,
    };

    const handleTitleChange = (e) => {
        setAlbumTitle(e.target.value);
    };
    
    const detectZoom = () => {
        if(!showGallery) return;
        if (window.visualViewport.scale < 1.05) {
          setHeight(window.innerHeight); 
        } else {
          setHeight('100vh');  
        }
    };
    
    const handleTouchMove = (e) => {
        if (e.touches.length === 2) {
          e.preventDefault(); 
          setHeight('100vh');
        }
    };

    useEffect(() => {
        if(!showGallery) return;
        const interval = setInterval(() => {
          detectZoom();
        }, 100);

        const fixedElement = document.getElementsByClassName('galleryContainer')[0];
    
        fixedElement?.addEventListener('touchmove', handleTouchMove, { passive: false });
    
        return () => {
          clearInterval(interval);
          fixedElement?.removeEventListener('touchmove', handleTouchMove);
        };
    }, [showGallery]);
    

    const changeAlbumTitle = async () => {
        if(albumTitle!==''){
            setIsEditing(false)
            const form = new FormData();
            form.append("title", albumTitle);
            const apiUrl = `${process.env.REACT_APP_STAG_URL}/api/work/update-my-deliverable-title/${collection_id}/`;
            try{
                const response = await axios.post(apiUrl, form, {
                    headers: {
                        Authorization: getAuthToken(),
                    },
                });
                setShowToast(true)
                setToastMessage(response?.data?.message)
            }catch(error){
                catchError(error)
            }
        }else{
            setShowToast(true)
            setToastMessage({'title':'Please enter a title'})
        }
    }

    const getFilteredData = () => {
        const currentData = allGuestPhotos ? collection?.photo_data?.flatMap(e=>e.data) : collection?.photo_data?.flatMap(e=>e.my_uploads)
        selectedOption === allPhotos && setImages(currentData)
        selectedOption === privatePhotos && setImages(hideSelectedObj[collection_id])
        selectedOption === publicPhotos && setImages(
            currentData.filter(item => 
                !hideSelectedObj[collection_id]?.some(hiddenItem => hiddenItem.photo_id === item.photo_id)
            )
        )
    }

    const splitTitle = (title) => {
        if (title.length > 11) {
            const [firstWord, ...rest] = title.split(" ");
            const restOfString = rest.join(" ");
            return [firstWord, restOfString];
        } else {
            return [title];
        }
    }

    const handleGuestUplaods = (value) => {
        setUpdateLoader(true)
        setCollection();
        window.location.href = value.url
    }

    const redirectHome = () => {
        setUpdateLoader(true)
        setCollection()
        window.location.href = data?.home_url?.url
    }

    const handleImageLoad = (index) => {
        setLoadedImages((prev) => ({ ...prev, [index]: true }));
    };

    useEffect(() => {
        if (thumbRef.current) {
          var widthThumb = thumbRef.current.clientWidth;
          if (widthThumb > 600) {
            thumbState.current = true;
          }
        }
    }, [thumbRef.current]);


    useEffect(()=>{
        if(faceSearchOnly){
            setFaceOnly(true)
        }
    },[])

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (selection || (event.target.tagName === 'A' || event.target.tagName === 'FORM')) {
                event.preventDefault();
                const confirmMessage = "Are you sure you want to navigate away? Your selection will be lost.";
                const confirmed = window.confirm(confirmMessage);
                if (!confirmed) {
                event.preventDefault();
                }
            }
            
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
    
        };
    }, [selection]);
    
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (!showGallery) return;

            if (event.key === 'Escape') {
                showGallery && closeGalleryHandler()
            }
            if(initialCarousel){
                if (event.key === "ArrowRight") {
                    carouselRef.current.goRight()
                } else if (event.key === "ArrowLeft") {
                    carouselRef.current.goLeft()
                }
            }
        };
    
        window.addEventListener('keydown', handleKeyDown);
    
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [showGallery, initialCarousel, photoIndex]);

    useEffect(() => {
        const targetStory = document.getElementsByClassName('openClass')[0];
        if (targetStory) {
            const parentElement = targetStory?.parentElement?.parentElement;
            if (parentElement) {
                const offsetLeft = targetStory.offsetLeft;
                parentElement.scrollTo({
                    left: offsetLeft-50,
                    behavior: "smooth",
                });
            }
        }
    }, [contentLoading]);

    const addToDone = () => {
        setIsAddToOpen(false); 
        cancelSelection()
        setCollection()
        setCollectionData()
        setRecallCollection(true)
    }

    const tncApproved = () => {
        setLoading(true);
        const authToken = getAuthToken();
        const headers = {
        Authorization: authToken,
        };
        axios
        .get(`${process.env.REACT_APP_STAG_URL}/api/work/guest/gallery/tnc/agree/`, {
            headers,
        })
        .then((response) => {
            setLoading(false);
            if(response?.data?.success){
                setCollectionData()
                setCollection()
                getBookingData()
            }else{
                setShowToast(true)
                setToastMessage(response?.data?.message)
            }
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
        });
    }

    const downloadVideo = () => {
        const tempAnchor = document.createElement('a')
        tempAnchor.href = currentQualityUrl
        tempAnchor.setAttribute('download', '');
        tempAnchor.click();
        setQualityVideo(false)
    }

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setFilterDropdown(false);
        }
        if (shareRef.current && !shareRef.current.contains(event.target)) {
            setShareDropdown(false);
        }
        if (moreRef.current && !moreRef.current.contains(event.target)) {
            setMoreDropdown(false);
        }
        if(likeViewRef.current && !likeViewRef.current.contains(event.target)){
            setLikeViewPopup(false)
        }
    };
    
    const exitFaceSearch = ()=>{
        getToTop()
        setShowFilters(true)
        setShareFaceFilter(false)
        setFaceSearchState(false)
        setFaceSearchOn(false)
        setFaceSearchData()
        const bodyDiv = document.getElementsByTagName('body')[0];
        bodyDiv.style.overflow = 'auto';
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.delete('face-search')
        navigate({ search: queryParams.toString()}, { replace: true });
        setCollectionData()
        setRecallCollection(true)
    }

    const indiFaceData = async (id) => {
        setUpdateLoader(true)
        const form = new FormData();
        form.append("id", id);
        if(shared_key){
            form.append("key", shared_key);
        }
        const apiUrl = `${process.env.REACT_APP_STAG_URL}/api/face/search/results/photos/`;
        try{
            const response = await axios.post(apiUrl, form, {
                headers: {
                    Authorization: getAuthToken(),
                },
            });
            setFaceFilter(false)
            setShowTooltip(false)
            updateFaceSearchResult(response?.data)
        }catch(error){
            catchError(error)
        }
        document.getElementsByTagName('body')[0].style.overflow = 'auto'   
    }

    const getPhotoCountFaceSearch = () => {
        switch (selectedOption) {
            case allPhotos:
                return faceSearchData?.photo_count
            case privatePhotos:
                return Object.values(faceHide).reduce((sum, arr) => sum + arr.length, 0)
            case publicPhotos:
                return (faceSearchData?.photo_count-Number(Object.values(faceHide).reduce((sum, arr) => sum + arr.length, 0)))
        }
    }

    const guestUploadTab = (type) => {
        if(faceSearchOn)exitFaceSearch()
        setSelectedOption(allPhotos)
        if(type==all){
            setAllGuestPhotos(true);
            setImages(collection?.photo_data?.flatMap((e)=>e.data));
        }else{
            setAllGuestPhotos(false);
            if(collectionData){
                yourUploads ? setImages(yourUploads) : setImages()
            }
            fetchImages()
        }
        getToTop();
    }

    const updateAlbum = (albumId, isChecked, currWorkId=null, photoId=null) => {
        const selectedData = faceSearchOn ? faceFormattedData : collectionFormattedData
        const selectedWorkId = get_selected_workId(selectedData, photoId)
        let apiUrl, option;
        if(addToSelection){ // addToSelection will check if the select button is triggered to move in add To selection mode or not
            if(isChecked){
                option = 'add_photo_album'
            }else{
                option = 'remove_photo_album'
            }
            apiUrl = getApiUrlFromSelectionType(addTo)
            apiUrl = `${apiUrl}${albumId}/${option}/`
            setUpdateLoader(true)
            const authToken = getAuthToken();
            const headers = {
                Authorization: authToken,
                "Content-Type": "application/json",
            };
            var data;
            if(photoId){
                data = { photo_id_data: [{work_id: selectedWorkId, select_list: [photoId], unselect_list: []}]}
            }else{
                data = { photo_id_data: multipleSelect };
            }
            axios
            .put(`${apiUrl}`, data, { headers })
            .then((response) => {
                setUpdateLoader(false)
                setShowToast(true)
                setToastMessage(response?.data?.message)
                setSingleAlbumData(response?.data?.photo_id_map)
                let tempAlbumData = albumData;
                tempAlbumData[albumId]['is_checked'] = isChecked
                setAlbumData(tempAlbumData)
            })
            .catch((error) => {
                console.error('Error removing image:', error);
                setUpdateLoader(false)
                setShowToast(true)
                setToastMessage(error.message)
            });
        }
    }

    const checkSelectionLengthValid = () => {
        if(multipleSelect.length==0){
            setShowToast(true);
            setToastMessage({'title':'Select atleast one image!'})
            return false;
        }else{
            const allSelectedImagesAreEmpty = multipleSelect.every(item => item.select_list.length === 0)
            if(allSelectedImagesAreEmpty){
                setShowToast(true);
                setToastMessage({'title':'Select atleast one image!'})
                return false;
            }
        }
        return true;
    }

    useEffect(() => {
        const targetEle = document.getElementsByClassName('_ZTBlf')[0];
        const actionEle = document.getElementsByClassName('topControls')[0]
        if (targetEle) {
            const handleClick = () => {
                if(targetEle?.getAttribute('aria-label').split(' ')[0]=='Start'){
                    actionEle.style.display = 'none'
                }
                if(targetEle?.getAttribute('aria-label').split(' ')[0]=='Pause'){
                    actionEle.style.display = 'flex'
                }
            };
    
            targetEle.addEventListener('click', handleClick);
    
            return () => {
                targetEle.removeEventListener('click', handleClick);
            };
        }
      }, [showGallery]);

    const handlePlayClick = (event) => {
        const descendantClassNames = getAllChildClassNames('topControls');
        const excludedClasses = ['topControls', 'popup-content', '_3lwW_', ...descendantClassNames];
        const isInsideExcluded = excludedClasses?.some(cls => event.target.closest(`.${cls}`));
        if (!isInsideExcluded) {
            setInitialCarousel(false);
        }
        const targetEle = document.getElementsByClassName('_ZTBlf')[0]
        const actionEle = document.getElementsByClassName('topControls')[0]
        targetEle.addEventListener('click', function(){
            if(targetEle?.getAttribute('aria-label').split(' ')[0]=='Start'){
                actionEle.style.display = 'none'
            }
            if(targetEle?.getAttribute('aria-label').split(' ')[0]=='Pause'){
                actionEle.style.display = 'flex'
            }
        })
    }

    const handleTabClick = (tab) =>{
        queryParams.delete('face-search')
        queryParams.delete('work_id')
        queryParams.set('tab', tab)
        navigate({ search: queryParams.toString()});
        tab==photos && queryParams.set('work_id', data?.data['photo-collection']?.[0].work_id);
        tab==guestUpload && queryParams.set('work_id', data?.data['guest-gallery']?.[0].work_id);
        tab==albumSelection && queryParams.set('work_id', data?.data['draft-album']?.[0].work_id);
        tab==userFolders && queryParams.set('work_id', data?.data['folders']?.[0].work_id);
        (tab==photos || tab==guestUpload || tab==albumSelection) && navigate({ search: queryParams.toString()});
        if(tab==photos && prevCollection == undefined){	
            handleCollections(data?.data?.['photo-collection']?.[0].work_id)	
        }else if(tab==photos && prevCollection){
            handleCollections(prevCollection)
        }else if(tab==guestUpload){
            handleCollections(data?.data?.['guest-gallery']?.[0].work_id)
        }else if(tab==albumSelection){
            handleCollections(data?.data?.['draft-album']?.[0].work_id)
        }else if(tab==userFolders){
            handleCollections(data?.data?.['folders']?.[0].work_id)
        }
        if(tab==photos){
            setTimeout(() => {
                getToTop(photoScroll)
            }, 100);
        }else{
            getToTop()
            setShowFilters(true)
        }
    }
    
    const faceFilterPhoto = async () => {
        setShareFaceFilter(false)
        document.getElementsByTagName('body')[0].style.overflow = 'hidden'
        const form = new FormData();
        var apiUrl
        if(shared_key){
            apiUrl = `${process.env.REACT_APP_STAG_URL}/api/face/search/results/shared/${shared_key}/`;
        }else{
            apiUrl = `${process.env.REACT_APP_STAG_URL}/api/face/search/results/${data?.booking_pk}/`;
        }
        setFaceFilter(true)
        setLoading(true)
        try {
            const response = await axios.post(apiUrl, form, {
                headers: {
                    Authorization: getAuthToken()
                },
            });
            setPastFaceSearches(response?.data)
            !response?.data?.length>0 && !faceSearchOnly && faceSearch()
        } catch (error) {
            setLoading(false)
            catchError(error)
        }

    }

    const shareMedia = () => {
        tab==guestUpload ? shareSelection() : setShareDropdown(!shareDropdown)
    }

    const moreMenu = () => {
        setMoreDropdown(!moreDropdown)
    }

    const handleBackNavigation = (navObj) => {
        navigateToUrl(navObj?.nav)
    }

    const getShareSelectiveLinkData = async (type, shareData) => {
        var note='';
        const apiUrl = `${process.env.REACT_APP_STAG_URL}/api/share/url/${data.booking_pk}/`;
        setShareSelectList([])
        try{
            const response = await axios.post(apiUrl, shareData, {
                headers: {
                    Authorization: getAuthToken(),
                },
            });
            setShareLinkData(response.data)
            if(response?.data?.nickname){
                nicknameInput.current.value = response?.data?.nickname;
            }else{
                nicknameInput.current.value = '';
            }
            setShareLinkPopup(true)
            if(type=='all'){
                note='<div>Note: All new uploaded data will be visible on this link</div>'
            }else if(type=='face_only'){
                note='<div><ul><li>Using this link, guests can click a selfie and only see their photos.</li><li>They will not have access to photos in which they are not present.</li></ul></div>'
            }
            const shareNoteElement = document.getElementById('shareNote');
            if (shareNoteElement) {
                shareNoteElement.innerHTML = note;
            }
            setUpdateLoader(false)
        }catch(error){
            catchError(error)
        }
    }

    const switchShareTabs = (switchTabs) => {
        setShareTab(switchTabs)
    }

    const getShareTab = (switchTabs) => {
        if(switchTabs==photos){
            return data?.data['photo-collection']
        }else if(switchTabs==videos){
            return data?.data['video']
        }else if(switchTabs==albums){
            return data?.data['album']
        }else{
            return []
        }
    }
 
    const getUnhiddenShareTab = (switchTabs) => {
        if(switchTabs==photos){
            return data?.data['photo-collection']
        }else if(switchTabs==videos){
            return data?.data['video'].filter(each => !hiddenWork[each.work_id])
        }else if(switchTabs==albums){
            return data?.data['album'].filter(each => !hiddenWork[each.work_id])
        }else{
            return []
        }
    }
 
    const shareDeliverable = (type) => {
        setShareTab(tab)
        const data = { data_id_list: [] };
        if(type!=='selective'){
            data[type] = true;
            setUpdateLoader(true)
        }else{
            setShareSelective(true)
            return;
        }
        getShareSelectiveLinkData(type, data)
    }

    const shareSelection = () => {
        const data = {data_id_list: tab==guestUpload ? [collection_id] : Object.values(shareSelectList).flat()}
        if(Object.values(shareSelectList).flat().length == 0 && tab!==guestUpload){
            setShowToast(true)
            setToastMessage({'title':'Select atleast one!'})
        }else{
            setShareSelective(false)
            getShareSelectiveLinkData('', data)
        }
    }

    const shareSelectMedia = (type, id) => {
        if(hiddenWork[id]) return;
        const ele = id.toString();
        setShareSelectList((prevState) => {
            const currentArray = prevState[type] || [];
            return {
                ...prevState,
                [type]: currentArray.includes(ele)
                    ? currentArray.filter((item) => item !== ele)
                    : [...currentArray, ele]
            };
        });
    };
    const shareSelectAllCollection = (type, data) => {
        setShareSelectList((prevState) => {
            const currentSelection = prevState[type] || [];
            if (data.length > 0) {
                const updatedSelection = data.reduce((acc, e) => {
                    if (!hiddenWork[e.work_id]) {
                        let id = e.work_id.toString();
                        if (acc.includes(id)) {
                            return [...acc]
                        } else {
                            return [...acc, id];
                        }
                    } else return [...acc]
                }, currentSelection);

                return { ...prevState, [type]: updatedSelection };
            } else {
                const { [type]: _, ...rest } = prevState;
                return { ...rest, [type]: [] };
            }
        });
    };

    const updateFaceSearchResult = (res) => {
        if(res.success === false){
            setShowToast(true)
            setToastMessage(res.message)
            if(faceSearchOnly){
                setUpdateLoader(true)
                faceFilterPhoto()
                setUpdateLoader(false)
            }
        }else{
            setFaceOnly(false)
            setFaceSearchOn(true)
            setFaceSearchState(false)
            setFaceCollection(res)
            setFaceSearchData(res)
            let tempData = res?.album_data?.album_data || {};
            setDefAlbumData(JSON.parse(JSON.stringify(tempData))); // Create a deep copy
            setAlbumData(tempData);
            setSingleAlbumData(res?.album_data?.photo_id_map || {})
            setFaceFilter(false)
            setFaceSelect({})
            setImages([])
            setSelectedOption(allPhotos)
            res?.photo_data.map((e) => {
                setFaceFormattedData((prevImages) => ({
                    ...prevImages,
                    [e.work_id]: e.data,
                }));
            });
            res?.photo_data.map((e) => {
                setFaceSelect((prevImages) => ({
                    ...prevImages,
                    [e.work_id]: e.private_data,
                }));
            });
            setFaceHide({})
            res?.photo_data.map((e) => {
                setFaceHide((prevImages) => ({
                    ...prevImages,
                    [e.work_id]: e.private_data,
                }));
            });
            if(res?.photo_data){
                res?.photo_data?.forEach((e)=>{
                        setImages((prev)=>([
                            ...prev,
                            ...e.data
                        ]))
                })
            }else{
                setImages([])
            }
            if(faceSearchOnly){
                if(res?.photo_data){
                    res?.photo_data.map((e) => {
                        setSelectImages((prevImages) => ({
                            ...prevImages,
                            [e.work_id]: [],
                        }));
                    });
                    queryParams.set('work_id', res?.photo_data[0].work_id)
                    navigate({ search: queryParams.toString() });
                }
            }
        }
        setUpdateLoader(false)
    }

    const addAndRemove = useCallback((work_id, photo_obj, updatedData) => {
        setMultipleSelect((prevMultipleSelect) => {
            return prevMultipleSelect.map((item) => {
                if (item.work_id === work_id) {
                    const isInSelect = updatedData?.some((i) => i.photo_id === photo_obj.photo_id);
                    if (!isInSelect) {
                        return {
                            ...item,
                            select_list: item.select_list.filter((e) => e !== photo_obj.photo_id),
                            unselect_list: [...item.unselect_list, photo_obj.photo_id],
                        };
                    } 
                    else{
                        return {
                            ...item,
                            unselect_list: item.unselect_list.filter((e) => e !== photo_obj.photo_id),
                            select_list: [...item.select_list, photo_obj.photo_id],
                        };
                    }
                }
                return item;
            });
        });
    }, []);

    const selectAllUpdateMultiple = (status, work_id) => {
        const selectedWorkId = work_id 
        setMultipleSelect((prevMultipleSelect) => {
            const index = prevMultipleSelect.findIndex((obj) => obj.work_id === Number(selectedWorkId));
            if(!faceSearchOn){
                if (index === -1) {
                    return [
                        ...prevMultipleSelect,
                        {
                            work_id: Number(selectedWorkId),
                            select_list: status 
                                ? (allGuestPhotos ? returnFilteredData()[selectedWorkId] : (deleteSelection ? yourUploads?.filter(item => item.can_delete) : returnFilteredData()[selectedWorkId])).map((obj) => obj.photo_id) 
                                : [],
                            unselect_list: status 
                                ? [] 
                                : returnFilteredData()[selectedWorkId].map((obj) => obj.photo_id),
                        }
                    ];
                } else {
                    return prevMultipleSelect.map((item, idx) => 
                        idx === index 
                            ? {
                                ...item,
                                select_list: status 
                                    ? (allGuestPhotos ? returnFilteredData()[selectedWorkId] : (deleteSelection ? yourUploads?.filter(item => item.can_delete) : returnFilteredData()[selectedWorkId])).map((obj) => obj.photo_id) 
                                    : [],
                                unselect_list: status 
                                    ? [] 
                                    : returnFilteredData()[selectedWorkId].map((obj) => obj.photo_id),
                            }
                            : item
                    );
                }
            }else{
                if (index === -1) {
                    return [
                        ...prevMultipleSelect,
                        {
                            work_id: Number(selectedWorkId),
                            select_list: status 
                                ? returnFilteredData()[selectedWorkId].map((obj) => obj.photo_id) 
                                : [],
                            unselect_list: status 
                                ? [] 
                                : returnFilteredData()[selectedWorkId].map((obj) => obj.photo_id),
                        }
                    ];
                } else {
                    return prevMultipleSelect.map((item, idx) => 
                        idx === index 
                            ? {
                                ...item,
                                select_list: status 
                                    ? returnFilteredData()[selectedWorkId].map((obj) => obj.photo_id) 
                                    : [],
                                unselect_list: status 
                                    ? [] 
                                    : returnFilteredData()[selectedWorkId].map((obj) => obj.photo_id),
                            }
                            : item
                    );
                }
            }
        });
    };

    const updateSingleSelect = useCallback((work_id, photo_obj, updatedData) => {
        work_id = Number(work_id)
        const index = multipleSelect.findIndex((obj) => obj.work_id === work_id);
        if (index !== -1) {
            addAndRemove(work_id, photo_obj, updatedData);
        } else {
            setMultipleSelect((prevMultipleSelect) => [
                ...prevMultipleSelect,
                {
                    work_id,
                    select_list: [],
                    unselect_list: [],
                },
            ]);
            addAndRemove(work_id, photo_obj, updatedData);
        }
    }, [multipleSelect, hideSelectedObj, collectionData]);


    const closeFacePopup = () => {
        setFaceFilter(false)
        document.getElementsByTagName('body')[0].style.overflow = 'auto'
    }

    const lockAlbum = () => {
        setUpdateLoader(true)
        const authToken = getAuthToken();
        const headers = {
            Authorization: authToken,
            "Content-Type": "application/json",
        };
        const api_url = `${process.env.REACT_APP_STAG_URL}/api/work/my/${collection_id}/lock/`
        axios
        .put(`${api_url}`, {} , { headers })
        .then((response)=>{
            setUpdateLoader(false)
            setShowToast(true)
            setToastMessage(response.data.message)
            setAlbumLocked(response.data.data.is_locked)
        })
        .catch((error)=>{
            setUpdateLoader(false)
            catchError(error)
        })
    }

    const faceSearch = () => {
        setSelection(false)
        setDownloadSelection(false)
        setHideSelection(false)
        if(faceSearchOnly){
            setFaceOnly(true);
            faceFilterPhoto();
        }else{
            const queryParams = new URLSearchParams(window.location.search);
            queryParams.set('face-search', true)
            navigate({ search: queryParams.toString()});
            setFaceFilter(false)
            setFaceSearchState(true)
            setShowTooltip(false);
            document.getElementsByTagName('body')[0].style.overflow = 'auto'
        }
    }

    const galleryHandler = (event) => {
        if(!hideSelection){
            setFilterDropdown(false)
            setPhotoIndex(parseInt(event?.currentTarget.id, 10));
            setIndexDisplay(event?.currentTarget.id);
            if(!event){
                setPhotoIndex(parseInt(location.hash.split('=')[1], 10))
                setIndexDisplay(location.hash.split('=')[1])
            }
            setShowGallery(true);
            const bodyDiv = document.getElementsByTagName('body')[0];
            bodyDiv.style.overflow = 'hidden';
            setInitialCarousel(true);
        }
    };

    const toggleSelectImage = (photo_obj='') => {
        if(!get_liked_data_is_downloadable(collection, collectionFormattedData, photo_obj))return;
        const selectedData = faceSearchOn ? faceFormattedData : collectionFormattedData
        const selectedWorkId = get_selected_workId(selectedData, photo_obj?.photo_id)
        var currentSelected;
        if(hideSelection){
            currentSelected = selectionTypeData(hide, selectedWorkId)
        }else if(downloadSelection){
            currentSelected = selectionTypeData(download, selectedWorkId)
        }else if(addToSelection){
            currentSelected = selectionTypeData(addTo, selectedWorkId)
        }else if(deleteSelection){
            currentSelected = selectionTypeData(deleteUploads, selectedWorkId)
        }
        const updatedObj = classObj.updateSelectedArray({[collection_id]: (faceSearchOn ? currentSelected : currentSelected[collection_id])}, photo_obj, selectedWorkId);
        if(faceSearchOn){
            !downloadSelection && !deleteSelection && setFaceSelect(prevSelectedImages => ({
                ...prevSelectedImages,
                [selectedWorkId]: updatedObj[selectedWorkId]
            }));
            const totalItems = Object.values(updatedObj).reduce((sum, arr) => sum + arr.length, 0)
            const filteredResult = Object.keys(updatedObj).reduce((total, key) => {
                const obj2PhotoIds = new Set(faceHide[key]?.map((item) => item.photo_id) || []);
                const filteredArray = updatedObj[key].filter((item) => !obj2PhotoIds.has(item.photo_id));
                return total + filteredArray.length;
              }, 0);
            setSelectionAll(prevState => ({
                ...prevState,
                [collection_id]: (selectedOption==publicPhotos ? filteredResult : totalItems) === Object.values(returnFilteredData()).flat().length
            }));
        }else{
            !downloadSelection && !deleteSelection && setSelectedImages(prevSelectedImages => ({
                ...prevSelectedImages,
                [selectedWorkId]: updatedObj[selectedWorkId]
            }));
            const totalItems = Object.keys(updatedObj)
            .filter((key) => collectionFormattedData[key])
            .reduce((sum, key) => sum + updatedObj[key].length, 0);
            const filteredResult = Object.keys(updatedObj)
            .filter((key) => collectionFormattedData[key])
            .reduce((total, key) => {
              const obj2PhotoIds = new Set(hideSelectedObj[key]?.map((item) => item.photo_id) || []);
              const filteredArray = updatedObj[key].filter((item) => !obj2PhotoIds.has(item.photo_id));
              return total + filteredArray.length;
            }, 0);
            setSelectionAll(prevState => ({
                ...prevState,
                [collection_id]:  (selectedOption==publicPhotos ? filteredResult : totalItems) === Object.values(returnFilteredData()).flat().length
            }));
        }
        (downloadSelection || addToSelection || deleteSelection) && setSelectImages((prev)=>({
            ...prev,
            [selectedWorkId] : updatedObj[selectedWorkId]
        }))
        updateSingleSelect(selectedWorkId, photo_obj, updatedObj[selectedWorkId])
        let tempData = defAlbumData;
        tempData && setAlbumData(JSON.parse(JSON.stringify(tempData))); // Create a deep copy
    };
    
    const updateSelectedPhotos = () => {
        if(isAdmin()){
            return;
        }else{
            if(downloadSelection || deleteSelection){
                if(!checkSelectionLengthValid()){
                    return;
                }
            }
            if(deleteSelection && !deleteConfirm){
                setDeletePopup(true)
                return;
            }
            var apiUrl = '';
            if(selectionType !== addTo){
                apiUrl = tab==albumSelection && selectionType==deleteUploads ? `${getApiUrlFromSelectionType(addTo)}${collection_id}/remove_photo_album/` : getApiUrlFromSelectionType(selectionType)
            }else{
                return;
            }
            selectionType!=viewPhotos && setUpdateLoader(true)
            const authToken = getAuthToken();
            const headers = {
                Authorization: authToken,
                "Content-Type": "application/json",
            };
            const data = { photo_id_data: multipleSelect };
            if(shared_key){
                data['shared_key'] = shared_key
            }
            const method = getApiMethod(selectionType, tab)
            axios({
                method: method,
                url: `${apiUrl}`,
                data: method === "GET" ? null : data,
                headers
            })
            .then((response) => {
                setDeleteConfirm(false)
                setHideSelectedObj(selectedImages)
                if(collection)collection.private_data = selectedImages[collection_id]
                setFaceHide(faceSelect)
                if((deleteSelection || selectionType==deleteUploads) && response?.data?.success){
                    if(!showGallery){
                        setCollectionData()
                        setCollection()
                        getBookingData()
                        setYourUploads()
                    }else{
                        setReloadDelete(true)
                        if(galleryImages.length==photoIndex){
                            window.location.hash = `#gallery?index=${1}`;
                            setPhotoIndex(1)
                        }
                        setGalleryImages(galleryImages.filter((image) => 
                            !multipleSelect.some((item) => item.select_list.includes(image.photo_id))
                        ));                        
                    }
                }
                if(tab==userFolders && selectionType!==viewPhotos && response?.data?.success){
                    setReloadDelete(true)
                }
                getLikesData()
                setMultipleSelect((prevSelect)=>
                    prevSelect.map(item=>({
                        ...item,
                        select_list: [],
                        unselect_list: []
                })))
                setSelectImages((prev) => {
                    const emptyArrays = Object.keys(prev).reduce((acc, key) => {
                        acc[key] = [];
                        return acc;
                    }, {});
                    return emptyArrays;
                });
                setSelectionAll({})
                if(downloadSelection || selectionType==download){
                    setDownloadDetails(response.data)
                    setIsPopupOpen(true)
                }
                
                setHideSelection(false)
                setDownloadSelection(false)
                setDeleteSelection(false)
                setSelection(false)
                selectedOption !== allPhotos && handleFilterCheckboxChange(selectedOption)
                setUpdateLoader(false)
                setUpdateSinglePhoto(false)
                if(selectionType !== download && selectionType !== viewPhotos){         //don't show toast for view photos and downloads and show for like photos only it title key is present
                    if(selectionType==likePhotos && !response.data.message.hasOwnProperty('title'))return;
                    setShowToast(true)
                    setToastMessage(response.data.message)
                }
            })
            .catch((error) => {
                console.error('Error removing image:', error);
                setUpdateLoader(false)
                if(selectionType == viewPhotos) return;
                setShowToast(true)
                setToastMessage(error.message)
            });
        }
    }

    const downloadFaceSearch = () => {
        setSelectionType(download)
        Object.keys(faceSearchOn ? faceFormattedData : collectionFormattedData).forEach((key) =>
            (faceSearchOn ? faceSearchData : collection)?.photo_data.map((e)=>{
                if(e.work_id==key){
                    if(selectionType==addTo){
                        e.can_add_to && e.is_album_required && selectAllUpdateMultiple(true, key)
                    }else if(selectionType==hide){
                        e.can_make_private && selectAllUpdateMultiple(true, key)
                    }else{
                        e.is_downloadable && selectAllUpdateMultiple(true, key)
                    }
                }
            })
        );
        setReadyFaceSearchDownload(true)
    }

    const play = document.getElementsByClassName("_ZTBlf");
    if (play[0]) play[0].style.marginLeft = "50px";

    const fullSize = document.getElementsByClassName("_lfOsC _dZ8C-");

    if (fullSize[1]) {
        fullSize[1].addEventListener("click", () => {
            const elem = thumbRef.current;
            if (!document.fullscreenElement) {
                setFullScreen(true);
                if (elem.requestFullscreen) {
                elem.requestFullscreen();
                } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
                } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen();
                }
            } else {
                setFullScreen(false);
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                }
                carouselRef.current.toggleIsPlaying()
            }
        });
    }

    const handleIndexChange = (e) => {
        setLikeViewPopup(false)
        if(showGallery && photoIndex){
            recordViews(galleryImages[e.curIndex]?.photo_id)
        }
        let num = parseInt(e.curIndexForDisplay, 10);
        setIndexDisplay(num);
        setPhotoIndex(num);
        window.location.hash = `#gallery?index=${num}`
        if(images?.length<indexDisplay){
            selectedOption !== allPhotos ? handleFilterCheckboxChange(selectedOption) : setImages(collection?.data?.slice(0, images?.length + numImages))
        }
        const element_scroll = document.getElementById(`gallery${indexDisplay}`);
        element_scroll?.scrollIntoView();
    };

    const okayHide = () => {
        document.getElementById('grideHidePopup').style.display = 'none';
        setGridFirstCheck(false)
    }

    const closeGalleryHandler = async () => {
        const bodyDiv = document.getElementsByTagName('body')[0];
        bodyDiv.style.overflow = 'auto';
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname + window.location.search
        );
        location.hash=''
        if(reloadDelete){
            setCollectionData()
            setCollection()
            getBookingData()
            setYourUploads()
            if(tab==userFolders){             //if library is selected set filter to all photos if no hidden photos are there after closing full view
                document.getElementsByClassName('my-masonry-grid_column').length!==0 && setSelectedOption(allPhotos)
            }
            if(faceSearchOn){
                setFaceSearchData((prevData) => ({
                    ...prevData,
                    photo_data: prevData.photo_data.map((item) => ({
                      ...item,
                      data: item.data.filter((dataItem) =>
                        galleryImages.some((ref) => ref.photo_id === dataItem.photo_id)
                      ),
                    })),
                }));
            }
            setReloadDelete(false)
            return;
        }
        if(tab==albumSelection){
            setCollection()
            setCollectionData()
            setRecallCollection(true)
        }
        setFaceSearchState(false)
        setShowGallery(false);
        fetchImages()
        handleFilterCheckboxChange(selectedOption)
    };

    const getDataByOption = (ele, selectedOption) => {
        if(faceSearchOn){
            switch (selectedOption) {
                case privatePhotos:
                  return faceHide[ele?.work_id];
                case publicPhotos:
                  return ele?.data.filter(obj1 => !faceHide[ele?.work_id].some(obj2 => obj2.photo_id === obj1.photo_id));
                default:
                  return ele?.data
            }
        }else{
            switch (selectedOption) {
                case privatePhotos:
                    const refrenceObj = new Set(yourUploads?.map(item => item?.photo_id));
                    const filteredArray = hideSelectedObj[ele?.work_id]?.filter(item => refrenceObj.has(item?.photo_id));
                    const unsortedPrivateData = (allGuestPhotos ? (tab==userFolders ? ele.private_data : hideSelectedObj[ele?.work_id]) : filteredArray)
                    const sortedPrivateData = sort_data_by_is_downloadable(unsortedPrivateData, collection ,collectionFormattedData)
                  return tab == userFolders && selection ? sortedPrivateData : unsortedPrivateData;;
                case publicPhotos:
                    const unsortedPublicData = (allGuestPhotos ? ele?.data : ele?.my_uploads).filter(obj1 => !(tab==userFolders ? ele.private_data : hideSelectedObj[ele?.work_id]).some(obj2 => obj2.photo_id === obj1.photo_id))
                    const sortePublicdData = sort_data_by_is_downloadable(unsortedPublicData, collection ,collectionFormattedData)
                  return tab == userFolders && selection ? sortePublicdData : unsortedPublicData;
                default:
                    const unsortedData = tab==photos ? images : (allGuestPhotos ? ele?.data : ele?.my_uploads)
                    const sortedData = sort_data_by_is_downloadable(unsortedData, collection ,collectionFormattedData)
                  return tab == userFolders && selection ? sortedData : unsortedData;
            }
        }
    }

    const handleAlbum = (albumId) =>{
        handleCollections(albumId)
        queryParams.set('work_id', albumId)
        queryParams.set('album_status', true)
        navigate({ search: queryParams.toString()});
        setAlbumView(true)
    }

    const closeAlbum = () =>{
        queryParams.delete('album_status')
        queryParams.delete('work_id')
        navigate({ search: queryParams.toString()});
        setAlbumView(false)
        setShowFilters(true)
    }

    const handleVideo = (videoId) =>{
        handleCollections(videoId)
        queryParams.set('work_id', videoId)
        queryParams.set('video_status', true)
        navigate({ search: queryParams.toString() });
        setVideoView(true)
    }

    const hideSelected = (workId, isHidden) =>{
        if (firstCheckMedia.current) {
            document.getElementById('mediaHidePopup').style.display = 'flex';
            document.getElementById('hideButtonMedia').innerText = isHidden ? 'Unhide' : 'Hide';
            document.getElementById('popupMediaTitle').innerText = isHidden ? 'Unhide' : 'Hide';
            document.getElementById('popupMediaDesc').innerText = isHidden ? 'Unhidden media are visible to everyone with whom they are shared' : 'Hidden media are invisible to everyone with whom they are shared';
            document.getElementById('hideButtonMedia').onclick = () => {
                handleHideMedia(workId, isHidden);
            };
        } else {
            const data = {'hide': !isHidden}
            const apiUrl = `${process.env.REACT_APP_STAG_URL}/api/work/${workId}/visibility/update/`
            const authToken = getAuthToken();
            const headers = {
                Authorization: authToken,
                "Content-Type": "application/json",
            };
            axios
            .post(`${apiUrl}`, data, { headers })
            .then((response) => {
                const updatedVideos = { ...hiddenWork, [workId]: !hiddenWork[workId] };
                setHiddenWork(updatedVideos);
                setUpdateLoader(false)
                setShowToast(true)
                setToastMessage(response.data.message)
            })
            .catch((error) => {
                console.error('Error removing image:', error);
                setUpdateLoader(false)
                setShowToast(true)
                setToastMessage(error.message)
            });
        }
    }

    const confirShareLost = () => {
        if(Object.values(shareSelectList).flat().length == 0 ){
            setShareSelective(false);
            setShareSelectList({})
        }else{
            const userConfirmed = window.confirm("All selections will be lost!");
            if (userConfirmed && Object.values(shareSelectList).flat().length > 0) { 
                setShareSelective(false);
                setShareSelectList({})
            }
        }
    }

    const closeVideo = () =>{
        queryParams.delete('video_status')
        queryParams.delete('work_id')
        navigate({ search: queryParams.toString()}, { replace: true });
        setVideoView(false)
        setShowFilters(true)
    }

    const getSelectionData = (key) => {
        setSelection(true)
        setSelectionType(key)
        if(key == hide){
            setHideSelection(true)
            selectionMode('', hide)
        }else if(key == download){
            setDownloadSelection(true)
            selectionMode('', download)
        }else if(key == addTo){
            setAddToSelection(true)
            selectionMode('', addTo)
        }else if(key==deleteUploads){
            setDeleteSelection(true)
            selectionMode('', deleteUploads)
        }
    }

    const qualityPopup = (list) => {
        setVideoDetails(list)
        setQualityVideo(true)
        list.map((e)=>{
            if(e.quality==1080){
                setCurrentQualityUrl(e.url)
            }
        })
    }

    const downloadCollection = () => {
        setUpdateLoader(true)
        const authToken = getAuthToken();
        const headers = {
            Authorization: authToken,
            "Content-Type": "application/json",
        };
        const data = { photo_id_data: [{work_id: Number(collection_id), select_list: [], unselect_list: []}]}
        if(shared_key){
            data['shared_key'] = shared_key
        }

        const apiUrl = `${process.env.REACT_APP_STAG_URL}/api/work/url/collection_imgs/${collection_id}/`

        axios
        .post(`${apiUrl}`, data, { headers })
        .then((response) => {
            setDownloadDetails(response?.data)
            setIsPopupOpen(true)
            setUpdateLoader(false)
        })
        .catch((error) => {
            console.error('Error removing image:', error);
            setUpdateLoader(false)
            setShowToast(true)
            setToastMessage(error.message)
        });
    }

    const toggleAddToPopup = () => {
        if(checkSelectionLengthValid()){
            setIsAddToOpen(!isAddToOpen);
        }
    };

    const toggleSingleAddToPopup = () => {
        setIsSingleAddToOpen(!isSingleAddToOpen);
        setAddToSelection(true)
    };

    const checkAlbumKey = (photoId, albumKey) => {
        albumKey = Number(albumKey)
        if(photoId in singleAlbumData){
            for(let i=0; i<singleAlbumData[photoId].length; i++){
                if(singleAlbumData[photoId][i] == albumKey){
                    return true;
                }
            }
        }
        return false;
    };

    const selectionTypeData = (selectionType, currId) => {
        if(selectionType==hide){
            if(selectionAll[currId]){
                return returnFilteredData()
            }else{
                return faceSearchOn ? faceHide : hideSelectedObj
            }
        }else if(selectionType==download || selectionType==addTo || selectionType==deleteUploads){
            if(selectionAll[currId]){
                // return faceSearchOn ? faceFormattedData : collectionData[currId].data
                return returnFilteredData()
            }else{
                return faceSearchOn ? Object.keys(faceFormattedData).reduce((acc, key) => {
                    acc[key] = [];
                    return acc;
                }, {}) : Object.keys(collectionFormattedData).reduce((acc, key) => {
                    acc[key] = [];
                    return acc;
                }, {})
            }
        }
    }

    const selectionMode = (status, selectionType) => {
        if (images?.length === 0) return;
        const currId = queryParams.get('work_id');
        if (gridFirstCheck && selectionType==hide) {
            document.getElementById('grideHidePopup').style.display = 'flex';
        }
        const data = Object.values(returnFilteredData()).flat() || [];
        const selectedData = selectionType==download || selectionType==addTo || selectionType==deleteUploads ? selectImages[currId] : (selectedImages[currId] ? selectedImages[currId] : collection?.photo_data.flatMap(e=>e.private_data));
        
        const totalItems = faceSearchOn && selectionType!==download && selectionType!==deleteUploads && selectionType!==addTo
            ? Object.values(faceSelect).reduce((sum, arr) => sum + arr.length, 0)
            : selectedData.length;
        let arrayToBeUpdated;

        if (status === '') {
            !faceSearchOn ? setSelectionAll((prev) => ({
                ...prev,
                [currId]: totalItems === data?.length
            })) : setSelectionAll((prev) => ({
                ...prev,
                [currId]: totalItems === images.length || totalItems === data?.length
            }));
            arrayToBeUpdated = selectionTypeData(selectionType, currId);
        } else if (status === true) {
            const canSelect = deleteSelection ? yourUploads?.filter(item => item?.can_delete) : yourUploads
            arrayToBeUpdated = faceSearchOn ? returnFilteredData() : ((tab==guestUpload && !allGuestPhotos) ? canSelect : returnFilteredData());
            Object.keys(returnFilteredData()).forEach((key) =>
                (faceSearchOn? faceSearchData : collection)?.photo_data.map((e)=>{
                    if(e.work_id==key){
                        if(selectionType==addTo){
                            e.can_add_to && e.is_album_required && selectAllUpdateMultiple(true, key)
                        }else if(selectionType==download){
                            e.is_downloadable && selectAllUpdateMultiple(true, key)
                        }else if(selectionType==hide){
                            e.can_make_private && selectAllUpdateMultiple(true, key)
                        }else if(selectionType==deleteUploads){
                            selectAllUpdateMultiple(true, key)
                        }
                    }
                })
            );
        } else if (status === false) {
            arrayToBeUpdated = faceSearchOn
                ? Object.keys(faceSelect).reduce((acc, key) => ({ ...acc, [key]: [] }), {})
                : Object.keys(selectedImages).reduce((acc, key) => ({ ...acc, [key]: [] }), {});
            Object.keys(returnFilteredData()).forEach((key) =>
                selectAllUpdateMultiple(false, key)
            );
        }
    
        if (status === 'selectAll') {
            const compareData = downloadSelection || addToSelection || deleteSelection ? selectImages[currId] : collectionData[currId]?.photo_data.flatMap(e=>e.private_data)
            setSelectionAll((prev) => ({
                ...prev,
                [currId]: faceSearchOn ? totalItems === images.length : compareData.length === data?.length
            }));
            
        }

        if (status !== 'selectAll') {
            const selectedObj = selectionModeArray(arrayToBeUpdated)
            setClassObj(selectedObj);
        }
    };
    

    const cancelSelection = () => {
        if(selection){
            setMultipleSelect((prevSelect)=>
                prevSelect.map(item=>({
                    ...item,
                    select_list: [],
                    unselect_list: []
            })))
            setSelectImages((prev) => {
                const emptyArrays = Object.keys(prev).reduce((acc, key) => {
                    acc[key] = [];
                    return acc;
                }, {});
                return emptyArrays;
            });
        }
        setHideSelection(false)
        setDownloadSelection(false)
        setDeleteSelection(false)
        setAddToSelection(false)
        setSelection(false)
        setDeletePopup(false)
        setSelectionAll({})
        if(faceSearchOn){
            setFaceSelect(faceHide)
        }else{
            setSelectedImages(hideSelectedObj)
        }
        setImages([])
        selectImages && setSelectImages((prev) => {
            const emptyArrays = Object.keys(prev).reduce((acc, key) => {
                acc[key] = [];
                return acc;
            }, {});
            return emptyArrays;
        });
        if(!faceSearchData?.photo_data){
            getFilteredData()
        }else{
            faceSearchData?.photo_data?.forEach((e)=>{
                setImages((prev)=>([
                    ...prev,
                    ...e.data
            ]))
        })
        }
    }

    const getToTop = (top=0) => {
        if(top==0){
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }else{
            window.scrollTo({
                top: top,
                behavior: 'auto'
            });
        }
    };

    const handleNextCollection = () => {
        getToTop();
        data?.data?.['photo-collection'].forEach((e, index)=>{
            if(e.work_id == collection_id && index < data?.data?.['photo-collection'].length-1){
                data?.data?.['photo-collection'][index+1].work_id && handleCollections(data.data['photo-collection'][index+1].work_id)
            }
        })
        setShowFilters(true)
    }

    const switchHD = async () => {
        if(get_is_downloadable(galleryImages[photoIndex-1].photo_id, collection) || get_is_downloadable(galleryImages[photoIndex-1].photo_id, faceSearchData)){
            setLoadingHD(true);
            setHdUrl((prev)=>!prev)
        }else {
            setShowToast(true)
            setToastMessage({'title':'HD not available'}) 
        }
    }

    const downloadImage = (image='') => {
        const checkIOS = getMobileOperatingSystem()
        if(image || (get_is_downloadable(galleryImages[photoIndex-1].photo_id, collection) || get_is_downloadable(galleryImages[photoIndex-1].photo_id, faceSearchData))){
            var link = document.createElement("a");
            var downloadUrl = hdUrl
            ? galleryImages[photoIndex - 1].src
            : galleryImages[photoIndex - 1].src;
            if(checkIOS !== "iOS"){
                link.href = image ? image.download_url : downloadUrl
                link.download = image ? `${image.identifier}.jpg` : `${galleryImages[photoIndex - 1].identifier}.jpg`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }else{
                window.open(`${process.env.REACT_APP_STAG_URL}/ios_download/?url=` + encodeURIComponent(downloadUrl), '_blank');
            }
        }else{
            setShowToast(true)
            setToastMessage({'title':'No download permission'}) 
        }
    };

    const handleFilterCheckboxChange = (filter) => {
        setSelectedOption(filter)
        if(faceSearchOn){
            setFaceSearchData(faceCollection)
        }else{
            const currentData = allGuestPhotos ? collection?.photo_data?.flatMap(e=>e.data) : collection?.photo_data?.flatMap(e=>e.my_uploads)
            if(filter == allPhotos){
                setImages(currentData)
            }else if(filter == privatePhotos){
                setImages(selectedImages[collection_id])
            }else if(filter == publicPhotos){
                setImages(currentData?.filter(img => !selectedImages[collection_id]?.some(el=> img.photo_id === el.photo_id)))
            }
        }
    }

    const recordViews = (photo_id) => {
        if (viewTimeoutRef.current) {
            clearTimeout(viewTimeoutRef.current);
        }

        viewTimeoutRef.current = setTimeout(() => {
            handleLikeViewPhoto(photo_id, viewPhotos);
        }, 2000);
    }

    const handleCollections = async (workId)=>{
        setRecallCollection(false)
        tab==photos && setPrevCollection(workId)
        setCollection(null)
        setFilterDropdown(false)
        setAlbumTitle('')
        setShowFilters(true)
        setSelectedOption(selectedOption)
        const imagesLength = images?.length
        setImages(null)
        let dataStore = collectionData;
        dataStore = dataStore ? dataStore : {};
        queryParams.set('work_id', workId)
        navigate({ search: queryParams.toString() });
        if(Object.keys(dataStore) && Object.keys(dataStore)?.length !== 0 && 
            dataStore.hasOwnProperty(workId) && 
            dataStore[workId]?.expires_at > Date.now() && tab!==albumSelection){
            dataStore[workId]?.photo_data?.forEach((e)=>e.private_data=selectedImages[workId] || [])
            setCollection(dataStore[workId])
            if(tab==userFolders){     // if library is selected then show photos sorted by epoch time i.e most recent first
                getTimeSortedData(dataStore[workId])
            }
            setAlbumLocked(dataStore[workId].is_locked)
            setAlbumTitle(dataStore[workId].title)
            !hideSelection && tab!==guestUpload && setSelectedImages((prevState) => ({
                ...prevState,
                [workId]: hideSelectedObj[workId]
            }));
            setCollectionFormattedData({})
            dataStore[workId]?.photo_data?.map((e) => {
                    setCollectionFormattedData((prevImages) => ({
                        ...prevImages,
                        [e.work_id]: e.data,
                    }));
                });
            let tempImg = dataStore[workId]?.photo_data?.flatMap((e) => e.data) || [];
            (queryParams?.get('tab')==photos || queryParams?.get('tab')==guestUpload) && photoScroll == 0 && setImages(tempImg.slice(0, numImages))
            if(faceSearchData?.photo_data){
                faceSearchData?.photo_data.map((e)=>{
                    if(e.work_id==workId){
                        setImages(e.data)
                    }
                })
            }
            if(queryParams?.get('tab')!==albumSelection)((queryParams?.get('tab')==photos || queryParams?.get('tab')==guestUpload) && photoScroll > 0 && (tempImg?.length < numImages || imagesLength > numImages)) ? setImages(tempImg?.slice(0, imagesLength)) : setImages(tempImg?.slice(0, numImages))
            setBookingPK(dataStore[workId].booking_pk);
            setYourUploads(dataStore[workId]?.photo_data?.flatMap((e) => e?.my_uploads))
            if(tab==videos && dataStore[workId].url_list){
                for(let i = dataStore[workId].url_list?.length; i>0; i--){
                    if(dataStore[workId].url_list[i-1].quality===defQuality){
                        setVideoUrl(dataStore[workId].url_list[i-1].url)
                    }
                }
            }
            selectedOption !== allPhotos && setFilteredData(true)
        }else{
            const form = new FormData();
            form.append("client_type", "mobile");
            setLoading(true)
            if (shared_key) {
                form.append("key", shared_key);
            }
            var api_url;
            if(tab==albumSelection){
                api_url = `${process.env.REACT_APP_STAG_URL}/api/work/my/${workId}/`
            }else if(tab==userFolders){
                api_url = `${process.env.REACT_APP_STAG_URL}/api/work/user/folder/${workId}/`
            }else{
                api_url = `${process.env.REACT_APP_STAG_URL}/api/work/${workId}/`
            }
            setContentLoading(true)
            try {
                var response;
                if(workId){
                    if(tab==albumSelection || tab==userFolders){
                        response = await axios.get(api_url, {
                            headers: {
                                Authorization: getAuthToken(),
                                "Content-Type": "multipart/form-data",
                            },
                        });
                    }else{
                        response = await axios.post(api_url, form, {
                            headers: {
                                Authorization: getAuthToken(),
                                "Content-Type": "multipart/form-data",
                            },
                        });
                    }
                }
                if(response && !response?.data?.success){
                    queryParams.delete('work_id')
                    navigate({ search: queryParams.toString()}, { replace: true });
                    window.location.reload();
                }
                dataStore[workId] = response?.data
                setCollectionData(dataStore)
                storedCollectionData = dataStore
                setCollection(response?.data);
                if(tab==userFolders){
                    getTimeSortedData(response?.data)
                }
                setAlbumLocked(response?.data?.is_locked)
                setAlbumTitle(response?.data?.title)
                setCollectionFormattedData({})
                response?.data?.photo_data?.map((e) => {
                    setCollectionFormattedData((prevImages) => ({
                        ...prevImages,
                        [e.work_id]: e.data,
                    }));
                });
                let tempData = response?.data?.album_data?.album_data || {};
                setDefAlbumData(JSON.parse(JSON.stringify(tempData))); // Create a deep copy
                setAlbumData(tempData);
                setSingleAlbumData(response?.data?.album_data?.photo_id_map || {})
                response?.data?.photo_data?.forEach((e) => {
                    setHideSelectedObj((prevHideData) => ({
                      ...prevHideData,
                      [e.work_id]: e.private_data,
                    }));
                });
                response?.data?.photo_data?.forEach((e) => {
                    setSelectedImages((prevSelectedData) => ({
                      ...prevSelectedData,
                      [e.work_id]: e.private_data,
                    }));
                });
                setSelectImages((prev)=>({
                    ...prev,
                    [workId]: []
                }))
                setSelectionAll((prev)=>({
                    ...prev,
                    [workId]: false
                }))
                hideSelection && selectionMode('selectAll', hide);
                let tempImages = response?.data?.photo_data?.flatMap((e) => e.data) || [];
                if(queryParams?.get('tab')!==albumSelection)(queryParams?.get('tab')==photos || queryParams?.get('tab')==guestUpload) && setImages(tempImages?.slice(0, numImages));
                setYourUploads(response?.data?.photo_data?.flatMap(e=>e?.my_uploads))
                if(location.hash.includes('#gallery')){
                    setShareFaceFilter(false)
                    setShowGallery(true)
                    window.location.hash = location.hash
                    galleryHandler()
                }
                setBookingPK(response?.data?.booking_pk)
                if(tab==videos){
                    for(let i = response?.data?.url_list?.length; i>0; i--){
                        if(response?.data?.url_list[i-1].quality===defQuality){
                            setVideoUrl(response?.data?.url_list[i-1].url)
                        }
                    }
                }
                setContentLoading(false)
                setLoading(false)
                selectedOption !== allPhotos && setFilteredData(true)
            } catch (error) {
                setContentLoading(false)
                setLoading(false)
                catchError(error)
                if(tab){
                    handleTabClick(tab)
                }else{
                    handleTabClick(photos)
                }
            }
        }
        !allGuestPhotos && guestUploadTab()
        getToTop()
    }

    useEffect(()=>{
        if(recallCollection){
            const unique = queryParams.get('work_id')
            handleCollections(unique)
        }
    },[recallCollection])

    useEffect(()=>{
        if(readyFaceSearchDownload || deleteConfirm){
            updateSelectedPhotos()
            setReadyFaceSearchDownload(false)
        }
    },[readyFaceSearchDownload, deleteConfirm])

    const handleOrientationChange = () => {
        setCurrentDimensions(window.innerWidth)
        const container = storyRef.current;
        const pastContainer = scrollContainerRef.current
        if(container?.clientWidth < container?.scrollWidth){
            if (window.innerWidth >= 1200) {
                if (container) {
                    const containerWidth = container.offsetWidth;
                    const pillWidth = 220 + 30;
                    const remainingSpace = containerWidth % pillWidth;
                    const pillsNum = containerWidth / pillWidth;
                    const visiblePillMargin = remainingSpace / 2;
                    var margin = Math.floor(visiblePillMargin/(pillsNum*2))
                    setPillMargin(10+margin)
                }
            }else if(window.innerWidth < 1200 && window.innerWidth >= 900){
                if (container) {
                    const containerWidth = container.offsetWidth;
                    const pillWidth = 100 + 74;
                    const remainingSpace = containerWidth % pillWidth;
                    const pillsNum = containerWidth / pillWidth;
                    var margin = Math.floor(remainingSpace/(pillsNum*2))
                    setPillMargin(18+margin)
                }
            }else if(window.innerWidth < 900 && window.innerWidth >= 600){
                if (container) {
                    const containerWidth = container.offsetWidth;
                    const pillWidth = 100 + 47;
                    const remainingSpace = containerWidth % pillWidth;
                    const pillsNum = containerWidth / pillWidth;
                    var margin = Math.floor(remainingSpace/(pillsNum*2))
                    setPillMargin(17+margin)
                }
            }else if(window.innerWidth < 600){
                if (container) {
                    const containerWidth = container.offsetWidth;
                    const pillWidth = 75 + 30;
                    const remainingSpace = containerWidth % pillWidth;
                    const pillsNum = containerWidth / pillWidth;
                    var margin = Math.floor(remainingSpace/(pillsNum*2))
                    setPillMargin(9+margin)
                }
            }
        }
        if(pastContainer?.clientWidth < pastContainer?.scrollWidth){
            if(window.innerWidth >= 600){
                if (pastContainer) {
                    const containerWidth = pastContainer.offsetWidth;
                    const pillWidth = 100 + 30;
                    const remainingSpace = containerWidth % pillWidth;
                    const pillsNum = containerWidth / pillWidth;
                    var margin = Math.floor(remainingSpace/(pillsNum*2))
                    setPastMargin(10+margin)
                }
            }else{
                if (pastContainer) {
                    const containerWidth = pastContainer.offsetWidth;
                    const pillWidth = 80 + 20;
                    const remainingSpace = containerWidth % pillWidth;
                    const pillsNum = containerWidth / pillWidth;
                    var margin = Math.floor(remainingSpace/(pillsNum*2))
                    setPastMargin(5+margin)
                }
            }
        }
    };

    const getTimeSortedData = (data) => {
        const mergedData = {
            work_id: collection_id,
            data: data?.photo_data?.flatMap(obj => [...obj.data])?.sort((a, b) => b.epoch_time - a.epoch_time) || [],
            private_data : data?.photo_data?.flatMap(obj => [...obj.private_data])?.sort((a, b) => b.epoch_time - a.epoch_time) || []
        };
        setUserFolderData([mergedData])
    }

    useEffect(() => {
        window.addEventListener('resize', handleOrientationChange);
    
        return () => {
          window.removeEventListener('resize', handleOrientationChange);
        };
    }, []);

    useEffect(()=>{
        handleOrientationChange()
    },[showArrow])

    useEffect(()=>{
            hideSelection && selectionMode('selectAll', hide)
            downloadSelection && selectionMode('selectAll', download)
            downloadSelection && selectionMode('selectAll', deleteUploads)
            addToSelection && selectionMode('selectAll', addTo)
    }, [collectionFormattedData])

    useEffect(()=>{
        if(photoScroll==0 && divRef.current && tab==photos && !contentLoading){  //it gets height of div from the top
            setDistanceFromTop(divRef.current.offsetTop+divRef.current.offsetHeight);
        }
    },[photoScroll, contentLoading]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const faceSearchEnabled = searchParams.get('face-search');
        if (faceSearchEnabled === 'true') {
          setFaceSearchState(true)
        }else {
            setFaceSearchState(false)
        }
    }, [location.search]);

    useEffect(()=>{
        if(data && tab==photos){
            data?.data?.['photo-collection']?.forEach((e,index)=>{
                if(e.work_id == collection_id){
                    data?.data?.['photo-collection']?.[index+1] ? setNextCollectionName(data?.data?.['photo-collection']?.[index+1].title) : setNextCollectionName(null)
                }
            })
        }
        if(!collection?.user_agreement){
            const tncDiv = document.getElementsByClassName('tnCText')[0]
            if(tncDiv){
                tncDiv.innerHTML = collection?.tnc
            }
        }
    },[collection])

    var unid = queryParams.get('tab')!==photos && collection?.work_id ? collection?.work_id.toString() : ''
    useEffect(()=>{
        const viewDiv = document.getElementById(unid)
        if(!videoView && !albumView && (tab==videos || tab==albums) && viewDiv){
            viewDiv.scrollIntoView({ behavior: "smooth" });
        }
    },[videoView, albumView])

    useEffect(()=>{
        if(faceSearchOnly){
            faceFilterPhoto()
        }
    },[])

    useEffect(() => {
        if (window.location.hash.includes("#gallery")) {
            setShowGallery(true);
        }else{
            setShowGallery(false);
            const bodyDiv = document.getElementsByClassName('my-masonry-grid');
            if(bodyDiv.length>0){
                Array.from(bodyDiv).forEach((e)=>{
                    e.style.display = 'flex'
                })
            }
        }
    }, [location]);

    useEffect(()=>{
        if(filteredData){
            getFilteredData(selectedOption)
            setFilteredData(false)
        }
    },[filteredData])

    useEffect(()=>{
        if(!collection || faceSearchOn) return;
        const handleScroll = () => {
            const position = window.scrollY;
            setScrollPosition(position);
            if(tab === photos)setPhotoScroll(position)
            if (position > prevScrollPosition && scrollPosition > 200) {
                setShowFilters(false)
            } else {
                setShowFilters(true)
            }
            setPrevScrollPosition(position);
            if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight*0.50 && collection?.photo_count > images?.length + numImages && selectedOption == allPhotos) {
                setLoading(true);
                setTimeout(() => {
                    setNextCollection(false);
                    selectedOption !== allPhotos ? handleFilterCheckboxChange(selectedOption) : (allGuestPhotos ? setImages(collection?.photo_data?.flatMap((e) => e.data).slice(0, images?.length + numImages)) : setImages(yourUploads));
                    setLoading(false);
                }, 1000);
            }
            if (window.innerHeight + document.documentElement.scrollTop + 200 > document.documentElement.offsetHeight && collection?.photo_count < images?.length + numImages && selectedOption == allPhotos && allGuestPhotos) {
                collection?.photo_count!== images?.length && setLoading(true);
                setTimeout(() => {
                    setLoading(false);
                    selectedOption !== allPhotos ? handleFilterCheckboxChange(selectedOption) : (allGuestPhotos ? setImages(collection?.photo_data?.flatMap((e) => e.data)) : setImages(yourUploads));
                    setNextCollection(true);
                }, 200);
            }
            if(selectedOption !== allPhotos || tab!==guestUpload){
                setNextCollection(true)
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    },[collection, faceSearchOn, prevScrollPosition, tab, photos, images, numImages, selectedOption, allPhotos, allGuestPhotos, guestUpload, handleFilterCheckboxChange, yourUploads])

    const leftDiv = document.getElementById('storyNav')
    useEffect(()=>{
        if(leftDiv){
            if(leftDiv.scrollWidth > leftDiv.clientWidth){
                setShowArrow(true)
            }
        }
    },[leftDiv])

    useEffect(()=>{
        if(downloadSelection){
            document.getElementById('hideDiv').innerText = 'Download'
        }else if(hideSelection){
            document.getElementById('hideDiv').innerText = 'Update'
        }else if(deleteSelection){
            document.getElementById('hideDiv').innerText = 'Delete'
        }
        if(!selection){
            (tab==photos || tab==guestUpload) && fetchImages()
        }
    },[selection])

    useEffect(() => {
        if(showGallery && photoIndex && galleryImages?.length > 0){
            recordViews(galleryImages[photoIndex-1]?.photo_id)
        }
        const body = document.getElementsByTagName('body')[0];
        if (shareSelective || showGallery) {
            body.style.overflow = 'hidden';
        } else {
            body.style.overflow = 'auto';
        }
    
        return () => {
            body.style.overflow = 'auto';
        };
    }, [shareSelective, showGallery, galleryImages]);

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer && scrollContainer.scrollWidth > scrollContainer.clientWidth) {
            setIsScrollable(true);
        } else {
            setIsScrollable(false);
        }
    }, [loading, pastFaceSearches]);

    const scrollLeft = () => {
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollLeft -= 100;
        }
    };

    const scrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft += 100;
        }
    };

    const scrollStory = (direction) =>{
        if(direction=='left'){
            leftDiv.scrollLeft = leftDiv.scrollLeft - 0.20*leftDiv.scrollWidth
        }else if(direction=='right'){
            leftDiv.scrollLeft = leftDiv.scrollLeft + 0.20*leftDiv.scrollWidth
        }
    }


    const handlePhotoHide = (photo_id) => {
        if(!isAdmin()){
            setSelectionType(hide)
            const selectedData = faceSearchOn ? faceFormattedData : collectionFormattedData;
            const selectedWorkId = get_selected_workId(selectedData, photo_id)
            const photoObj = faceSearchOn 
                ? faceFormattedData[selectedWorkId].find((obj) => obj.photo_id === photo_id) 
                : collectionFormattedData[selectedWorkId].find((obj) => obj.photo_id === photo_id);
            if (firstCheck.current) {
                document.getElementById('hidePopupDiv').style.display = 'flex';
                
                const isHidden = faceSearchOn
                    ? faceHide[selectedWorkId].some(e => e.photo_id === photo_id)
                    : hideSelectedObj[collection_id].some(e => e.photo_id === photo_id);
        
                document.getElementById('hideButtonGallery').innerText = isHidden ? 'Unhide' : 'Hide';
                document.getElementById('popupTitle').innerText = isHidden ? 'Unhide' : 'Hide';
                document.getElementById('popupDesc').innerText = isHidden
                    ? 'Unhidden photos are visible to everyone with whom they are shared'
                    : 'Hidden photos are invisible to everyone with whom they are shared';
        
            } else {
                setMultipleSelect((prevMultipleSelect) => {
                    const index = prevMultipleSelect.findIndex((obj) => obj.work_id === selectedWorkId);
        
                    if (index === -1) {
                        prevMultipleSelect = [
                            ...prevMultipleSelect,
                            { work_id: selectedWorkId, select_list: [], unselect_list: [] },
                        ];
                    }
        
                    return prevMultipleSelect.map((item) => {
                        if (item.work_id === selectedWorkId) {
                            const isInSelect = faceSearchOn
                                ? JSON.stringify(faceHide[selectedWorkId]).includes(photo_id)
                                : JSON.stringify(hideSelectedObj[selectedWorkId]).includes(photo_id);
                            if (isInSelect) {
                                if (!faceSearchOn) {
                                    setSelectedImages((prev) => ({
                                        ...prev,
                                        [selectedWorkId]: prev[selectedWorkId]?.filter((img) => img.photo_id !== photo_id) || [],
                                    }));
                                } else {
                                    setFaceSelect((prev) => ({
                                        ...prev,
                                        [selectedWorkId]: prev[selectedWorkId]?.filter((img) => img.photo_id !== photo_id) || [],
                                    }));
                                }
                                setSelectionAll((prev)=>({
                                    ...prev,
                                    [collection_id]: false
                                }))

                                return {
                                    ...item,
                                    select_list: item.select_list.filter((e) => e !== photo_id),
                                    unselect_list: [...item.unselect_list, photo_id],
                                };
                            } else {
                                if (!faceSearchOn) {
                                    setSelectedImages((prev) => ({
                                        ...prev,
                                        [selectedWorkId]: [...prev[selectedWorkId] || [], photoObj],
                                    }));
                                } else {
                                    setFaceSelect((prev) => ({
                                        ...prev,
                                        [selectedWorkId]: [...prev[selectedWorkId] || [], photoObj],
                                    }));
                                }
                                return {
                                    ...item,
                                    unselect_list: item.unselect_list.filter((e) => e !== photo_id),
                                    select_list: [...item.select_list, photo_id],
                                };
                            }
                        }
                        return item;
                    });
                });
                setUpdateSinglePhoto(true);
            }
        }
    };

    const handleLikeViewPhoto = (photo_id, type) => {
        const selectedData = faceSearchOn ? faceFormattedData : collectionFormattedData;
        const selectedWorkId = get_selected_workId(selectedData, photo_id)
        const inSelect = type==viewPhotos ? false : (likeData[selectedWorkId]?.[photo_id] && likeData[selectedWorkId]?.[photo_id]?.user_liked ? true : false)
        setSelectionType(type)
        setMultipleSelect((prevMultipleSelect) => {
            const index = prevMultipleSelect.findIndex((obj) => obj.work_id === selectedWorkId);
            if (index === -1) {
                prevMultipleSelect = [
                    ...prevMultipleSelect,
                    { work_id: selectedWorkId, select_list: [], unselect_list: [] },
                ];
            }

            return prevMultipleSelect.map((item) => {
                if (item.work_id === selectedWorkId) {
                    if(!inSelect){
                        return {
                            ...item,
                            unselect_list: item.unselect_list.filter((e) => e !== photo_id),
                            select_list: [...item.select_list, photo_id],
                        };
                    }else{
                        return {
                            ...item,
                            select_list: item.select_list.filter((e) => e !== photo_id),
                            unselect_list: [...item.unselect_list, photo_id],
                        };
                    }
                }
                return item;
            });
        });
        setUpdateSinglePhoto(true);
    }

    const handleDeletePhoto = (photo_id) => {
        const selectedData = faceSearchOn ? faceFormattedData : collectionFormattedData;
        const selectedWorkId = get_selected_workId(selectedData, photo_id)
        setSelectionType(deleteUploads)
        setMultipleSelect((prevMultipleSelect) => {
            const index = prevMultipleSelect.findIndex((obj) => obj.work_id === selectedWorkId);
            if (index === -1) {
                prevMultipleSelect = [
                    ...prevMultipleSelect,
                    { work_id: selectedWorkId, select_list: [], unselect_list: [] },
                ];
            }

            return prevMultipleSelect.map((item) => {
                if (item.work_id === selectedWorkId) {
                    return {
                        ...item,
                        unselect_list: item.unselect_list.filter((e) => e !== photo_id),
                        select_list: [...item.select_list, photo_id],
                    };
                }
                return item;
            });
        });
        if(!deleteConfirm){
            setDeletePopup(true)
        }
    }

    const toggleSelection = () => {
        var selectionType;
        if(hideSelection)selectionType=hide
        if(downloadSelection)selectionType=download
        if(deleteSelection)selectionType=deleteUploads
        if(addToSelection)selectionType=addTo
        setSelectionAll((prevState) => ({
            ...prevState,
            [collection_id]: !selectionAll[collection_id]
        }));
        if(!selectionAll[collection_id]){
            if(hideSelection){
                if(faceSearchOn){
                    selectedOption == publicPhotos ? setFaceSelect(faceFormattedData) : setFaceSelect(returnFilteredData())
                }else{
                    selectedOption == publicPhotos ? setSelectedImages((prevSelectedImages) => ({
                        ...prevSelectedImages,
                        ...Object.keys(collectionFormattedData).reduce((acc, key) => {
                          acc[key] = [
                            ...(prevSelectedImages[key] || []),
                            ...collectionFormattedData[key].filter(
                              (newItem) =>
                                !newItem?.processing &&
                                !(prevSelectedImages[key] || []).some(
                                  (existingItem) => existingItem.photo_id === newItem.photo_id
                                )
                            ),
                          ];
                          return acc;
                        }, {}),
                      })) : setSelectedImages((prevSelectedImages) => ({
                        ...prevSelectedImages, 
                        ...Object.keys(returnFilteredData()).reduce((acc, key) => {
                          acc[key] = [
                            ...(prevSelectedImages[key] || []),
                            ...returnFilteredData()[key].filter(
                              (newItem) =>
                                !newItem?.processing &&
                                !(prevSelectedImages[key] || []).some(
                                  (existingItem) => existingItem.photo_id === newItem.photo_id
                                )
                            ),
                          ];
                          return acc;
                        }, {}),
                      }))
                }
            }
            
            if(downloadSelection || addToSelection || deleteSelection){
                const result = (addToSelection || downloadSelection) ? (faceSearchOn ? faceSearchData : collection)?.photo_data.reduce((acc, item) => {
                    if (addToSelection && item.can_add_to && item.is_album_required) {
                        acc[item.work_id] = item?.data;
                    }
                    if(downloadSelection && item.is_downloadable){
                        acc[item.work_id] = item?.data;
                    }
                    return acc
                }, {}) : returnFilteredData()
                
                setSelectImages((prevState) => {
                    const updatedState = { ...prevState };
                  
                    Object.keys(result).forEach((key) => {
                      if (updatedState[key]) {
                        const existingIds = new Set(updatedState[key].map((item) => item.photo_id));
                        let newData = result[key].filter((item) => !existingIds.has(item.photo_id));
                        if (deleteSelection) {
                            newData = newData.filter(item => item.can_delete !== false);
                        }
                        updatedState[key] = [...updatedState[key], ...newData];
                      } else {
                        updatedState[key] = deleteSelection 
                            ? result[key].filter(item => item.can_delete !== false) 
                            : result[key]
                      }
                    });
                  
                    return updatedState;
                });
            }
            selectionMode(true, selectionType)
        }else{
            !faceSearchOn && !downloadSelection && !addToSelection && !deleteSelection && setSelectedImages((prevState) => {
                const newState = Object.keys(prevState).reduce((acc, key) => {
                  acc[key] = Object.keys(collectionFormattedData).includes(key)
                    ? (selectedOption==publicPhotos && hideSelection ? hideSelectedObj[key] : [])
                    : prevState[key];
                  return acc;
                }, {});
                return newState;
              });
            (downloadSelection || addToSelection || deleteSelection) && setSelectImages((prevState) => {
                const newState = Object.keys(prevState).reduce((acc, key) => {
                  acc[key] = Object.keys(faceSearchOn ? faceFormattedData : collectionFormattedData).includes(key)
                    ? []
                    : prevState[key];
                  return acc;
                }, {});
                return newState;
              });
            faceSearchOn && setFaceSelect(prevState => {
                const newState = Object.keys(prevState).reduce((acc, key) => {
                  acc[key] = (selectedOption==publicPhotos && hideSelection ? faceHide[key] : []);;
                  return acc;
                }, {});
                return newState;
              });
            selectionMode(false, selectionType)
        }
    }

    const cancelHide = () => {
        firstCheck.current = false
        document.getElementById('hidePopupDiv').style.display='none'
    }

    const handleHideImage = async (photo_id) => {
        firstCheck.current = false
        document.getElementById('hidePopupDiv').style.display='none'
        handlePhotoHide(photo_id)
    }

    const cancelMediaHide = () => {
        firstCheckMedia.current = false
        document.getElementById('mediaHidePopup').style.display='none'
    }

    const handleHideMedia = async (work_id, isHidden) => {
        firstCheckMedia.current = false
        document.getElementById('mediaHidePopup').style.display='none'
        hideSelected(work_id, isHidden)
    }

    const indirectUpload = () => {
        const targetUpload = document.getElementsByClassName('uploadButton')[1]
        if(targetUpload){
            targetUpload.click()
        }
    }

    const getLikesData = (initial=false) => {    // initial is used to call this function on first mount and when selection type is likePhotos or viewPhotos
        if(selectionType==likePhotos || selectionType==viewPhotos || initial){
            const apiUrl = `${process.env.REACT_APP_STAG_URL}/api/work/photo/likes/`;
            const params = { work_id_lst: JSON.stringify(data?.work_id_lst) };
            if(shared_key){
                params['shared_key'] = shared_key
            }
            if(!data?.work_id_lst || data?.work_id_lst.length==0){
                return;
            }
            const authToken = getAuthToken();
            const headers = {
                Authorization: authToken,
                "Content-Type": "application/json",
            };
            axios
            .get(apiUrl, { params, headers })
            .then((response) => {
                setLikeData(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching likes data:', error);
                setUpdateLoader(false);
            });
        }
    }

    const returnFilteredData = () => {
        if(faceSearchOn){
            switch (selectedOption) {
                case allPhotos:
                    return faceFormattedData
                case privatePhotos:
                    return faceHide
                case publicPhotos:
                    return Object.fromEntries(
                        Object.entries(faceFormattedData).map(([key, dataArray]) => [
                          key,
                          dataArray.filter(
                            (item) => !faceHide[key]?.some((privateItem) => privateItem.photo_id === item.photo_id)
                          ),
                        ])
                    )
            }
        }else{
            const refrenceObj = new Set(yourUploads?.map(item => item?.photo_id));
            const filteredArray = hideSelectedObj[collection_id]?.filter(item => refrenceObj.has(item.photo_id));
            const filteredYourUploads = yourUploads?.filter(item => !item?.processing);
            switch (selectedOption) {
                case allPhotos:
                    return (allGuestPhotos ? collectionFormattedData : {[collection_id]: filteredYourUploads})
                case privatePhotos:
                    return Object.keys(collectionFormattedData).reduce((acc, key) => {
                        if (hideSelectedObj[key]) {
                          acc[key] = allGuestPhotos ? hideSelectedObj[key] : filteredArray;
                        }
                        return acc;
                      }, {}) 
                case publicPhotos:
                    return Object.fromEntries(
                        Object.entries(allGuestPhotos ? collectionFormattedData : {[collection_id]: filteredYourUploads}).map(([key, dataArray]) => [
                          key,
                          dataArray.filter(
                            (item) => !(allGuestPhotos ? hideSelectedObj[key] : filteredArray)?.some((privateItem) => privateItem.photo_id === item.photo_id)
                          ),
                        ])
                    )
            }
        }
    }
    
    const cropImage = (imageUrl, boundingBox, index) => {
        const originalCanvas = document.createElement('canvas');
    
        const image = new Image();
        image.crossOrigin = 'anonymous';
        image.src = imageUrl;
    
        image.onload = function () {
          const ctx = originalCanvas.getContext('2d');
          originalCanvas.width = image.width;
          originalCanvas.height = image.height;
    
          ctx.drawImage(image, 0, 0, image.width, image.height);
    
          const cropX = boundingBox.Left * image.width;
          const cropY = boundingBox.Top * image.height;
          const cropWidth = boundingBox.Width * image.width;
          const cropHeight = boundingBox.Height * image.height;
    
          const croppedCanvas = document.createElement('canvas');
          croppedCanvas.width = cropWidth;
          croppedCanvas.height = cropHeight;
          const croppedCtx = croppedCanvas.getContext('2d');
    
          croppedCtx.drawImage(originalCanvas, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);
    
          const croppedImageDataUrl = croppedCanvas.toDataURL('image/png');
          setCroppedImages(prev => ({ ...prev, [index]: croppedImageDataUrl }));
        };
        
        setTimeout(()=>{
            setLoading(false)
        },100)
        
        image.onerror = function (err) {
          console.error('Failed to load the image', err);
        };
    };

    const downloadMethod = () => {
        setDownloadPopup(true)
    }

    const copyLinkUrl = (link) => {
        setShowToast(true)
        setToastMessage({'title':'Copied!'})
        copy(link)
    };

    // const collectionApiCall = async (workId) => {
    //     if(workId){
    //         const form = new FormData();
    //         form.append("client_type", "mobile");
    //         if (shared_key) {
    //             form.append("key", shared_key);
    //         }
    //         const api_url = `${process.env.REACT_APP_STAG_URL}/api/work/${workId}/`;
    //         try {
    //             const response = await axios.post(api_url, form, {
    //                 headers: {
    //                     Authorization: getAuthToken(),
    //                     "Content-Type": "multipart/form-data",
    //                 },
    //             });
    //             storedCollectionData[workId] = response.data
    //             if(collectionData && !(workId in collectionData))setCollectionData(storedCollectionData)
    //             let uniq = data?.data['photo-collection'][currentId].work_id
    //             while (uniq in storedCollectionData) {
    //                 currentId++;
    //                 uniq = data?.data['photo-collection'][currentId]?.work_id;
    //             }
    //             if (!(uniq in storedCollectionData)) {
    //                 collectionApiCall(uniq)
    //             }
    //         } catch (error) {
    //             setContentLoading(false)
    //             catchError(error)
    //             if(tab){
    //                 handleTabClick(tab)
    //             }else{
    //                 handleTabClick(photos)
    //             }
    //         }
    //     }
    // }

    // const getAllCollectionData = () => {
    //     if(queryParams.get('tab')=='photos' && !(collectionData && collection_id in collectionData)){
    //         collectionApiCall(collection_id)
    //     }
    // }

    const fetchImages = async () => {
        var galleryImagesArr;
        if (faceSearchOn) {
            const galleryAll = faceSearchData?.photo_data?.flatMap((imageObj) => 
                imageObj?.data?.map((image) => ({
                  src: hdUrl ? image.download_url : image.view_url,
                  alt: image.identifier,
                  photo_id: image.photo_id
            })))
            const galleryPrivate = Object.values(faceHide).flatMap((imageObj) =>
                imageObj.map((image) => ({
                  src: hdUrl ? image.download_url : image.view_url,
                  alt: image.identifier,
                  photo_id: image.photo_id
                }))
              )
            if(selectedOption==privatePhotos){
                galleryImagesArr = galleryPrivate
            }else if(selectedOption==publicPhotos){
                galleryImagesArr = galleryAll?.filter(
                    image => !galleryPrivate.some(hiddenImage => hiddenImage.photo_id === image.photo_id)
                );
            }else{
                galleryImagesArr = galleryAll
            }
            
        }else{
            const galleryAll = (tab==userFolders ? userFolderData : collection?.photo_data)?.flatMap((imageObj) => 
                imageObj[allGuestPhotos ? "data" : "my_uploads"].map((image) => ({
                  src: hdUrl ? image.download_url : image.view_url,
                  alt: tab==guestUpload ? image.user_full_name : image.identifier,
                  photo_id: image.photo_id
            })))
            const refrenceObj = new Set(yourUploads?.map(item => item?.photo_id));
            const validWorkIds = new Set(collection?.photo_data?.map(item => item?.work_id));
            const filteredObject = Object.keys(hideSelectedObj)
                .map(Number)
                .filter(work_id => validWorkIds.has(work_id))
                .reduce((acc, work_id) => {
                    acc[work_id] = hideSelectedObj[work_id];
                    return acc;
                }, {});
            const filteredArray = hideSelectedObj[collection_id]?.filter(item => refrenceObj.has(item.photo_id));
            const galleryPrivate = Object.values(filteredObject).flatMap((imageObj) =>
                (allGuestPhotos ? imageObj : filteredArray).map((image) => ({
                  src: hdUrl ? image.download_url : image.view_url,
                  alt: image.identifier,
                  photo_id: image.photo_id,
                  epoch_time: image?.epoch_time || null
                }))
            )
            if(selectedOption===privatePhotos){
                const sortedPrivateGallery = galleryPrivate?.sort((a, b) => b?.epoch_time - a?.epoch_time)
                galleryImagesArr = tab==userFolderData ? sortedPrivateGallery : galleryPrivate
            }else if(selectedOption == publicPhotos){
                const galleryPublic = galleryAll?.filter(
                    image => !galleryPrivate.some(hiddenImage => hiddenImage.photo_id === image.photo_id)
                );
                const sortedPrivateGallery = galleryPublic?.sort((a, b) => b?.epoch_time - a?.epoch_time)
                galleryImagesArr = tab==userFolderData ? sortedPrivateGallery : galleryPublic
            }
            else{
                galleryImagesArr = galleryAll
            }
        }
        setGalleryImages(galleryImagesArr);
        const img = new Image();
        img.src = photoIndex && showGallery && galleryImagesArr[Number(photoIndex-1)]?.src
        img.onload = function() {
            setLoadingHD(false);
        };
    };

    const getBookingData = (flag=true) => {
        if(tab==guestUpload && !shared_key){
            setLoading(true)
            const authToken = getAuthToken();
            const headers = {
            Authorization: authToken,
            };
            params={}
            if(for_passkey) {
                params['passcode'] = for_passkey
            }
            axios
            .get(`${process.env.REACT_APP_STAG_URL}/api/work/guest-gallery/booking/${id}/`, {
                headers,
                params
            })
            .then((response) => {
                if(response?.data?.success == false){
                    setWorkIdError(true);
                    setWorkIdErrorMessage(response?.data?.message);
                    return
                }
                setData(response.data);
                setLoading(false);
                setCollectionData()
                setCollection()
                if(!flag){
                    setAllGuestPhotos(flag)
                }else{
                    setAllGuestPhotos(allGuestPhotos)
                }
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
            });
        }else{
            var api_url
            var params = {}
            if(shared_key){
                if(faceSearchOnly){
                    return;
                }
                api_url = `${process.env.REACT_APP_STAG_URL}/api/share/${shared_key}/`
            }else if(tab==albumSelection){
                api_url = `${process.env.REACT_APP_STAG_URL}/api/work/draft-album/booking/${id}/`
            }else if(tab==userFolders){
                api_url = `${process.env.REACT_APP_STAG_URL}/api/work/user/folders/`
            }else{
                api_url = `${process.env.REACT_APP_STAG_URL}/api/work/booking/${id}/`
                if(for_passkey) {
                    params['passcode'] = for_passkey
                }
            }
            if((id || shared_key || tab==userFolders) && id!=='null'){
                axios({
                    url: api_url,
                    method: "get",
                    params,
                    headers: {
                        Authorization: getAuthToken(),
                    },
                    })
                    .then((response) => {
                        if(!response?.data?.success){
                            setWorkIdError(true);
                            setWorkIdErrorMessage(response?.data?.message);
                            return
                        }

                        if(Object.keys(response?.data?.data).length == 0){
                            setWorkIdError(true);
                            setWorkIdErrorMessage(response?.data?.message);
                            return
                        }
                        
                        if((queryParams.get('tab') == photos && !response?.data?.data?.['photo-collection']) || (queryParams.get('tab') == videos && !response?.data?.data?.['video']) || (queryParams.get('tab') == albums && !response?.data?.data?.['album'])){
                            queryParams.delete('tab')
                            queryParams.delete('work_id')
                            navigate({ search: queryParams.toString()}, { replace: true });
                            window.location.reload();
                        }

                        setData(response?.data);
                        setCollectionData()
                        setCollection()
                        if(!flag){
                            setAllGuestPhotos(flag)
                        }else{
                            setAllGuestPhotos(allGuestPhotos)
                        }
                    })
                    .catch((error) => {
                        catchError(error)
                    });
            }
        }
    }

    const get_photo_like_view_data = (photo_id) => {
        const selectedData = faceSearchOn ? faceFormattedData : collectionFormattedData;
        const selectedWorkId = get_selected_workId(selectedData, photo_id)
        const user_list = likeData?.[selectedWorkId]?.[photo_id]?.user_list
        return {
            user_list,
            firstFewUsers : user_list?.length >= 2 ? user_list?.slice(0, 2) : user_list,
            counts : {
                total_likes : likeData?.[selectedWorkId]?.[photo_id]?.total_likes,
                total_views : likeData?.[selectedWorkId]?.[photo_id]?.total_views
            }
        }
    }

    const get_like_view_string = (photo_id) => {
        const likes = get_photo_like_view_data(photo_id).counts.total_likes
        const views = get_photo_like_view_data(photo_id).counts.total_views
        if(likes && views){
            return `Likes & Views`
        }else if(likes){    
            return `Likes`
        }else if(views){
            return `Views`
        }
    }
        
    const addNickname = async () => {
        try {

            const nicknameValue = nicknameInput.current.value;

            if(nicknameValue.trim() == ""){
                setShowToast(true)
                setToastMessage({'title': "need to enter some text for the title"})
                return;
            }
    
          const response = await fetch(getAPIUrl(`/api/share/url/nickname/${shareLinkData?.share_passcode}/`), {
            method: 'PUT',
            headers: {
                Authorization: getAuthToken(),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ nickname: nicknameValue, booking_pk: bookingPK }),
          });
    
          const res = await response.json();
    
          if (res.success) {
            setLoading(false);
            setNickname(nicknameValue)
          } else {
            setLoading(false)
          }
        } catch (error) {
            setLoading(false)
            console.error('error while adding nickname:', error);
            setNickname("");
        }
    }

    const clickEditNameIcon = () => {
        if (nicknameInput.current) {
            nicknameInput.current.value = nickname;
        }
        setNickname("");
    }

    const getSelectedData = () => {
        switch (tab) {
            case photos:
                return data.data['photo-collection'];
            case guestUpload:
                return data.data['guest-gallery'];
            case albumSelection:
                return data?.data['draft-album'];
            case userFolders:
                return data?.data['folders'];
            default:
                return [];
        }
    }

    useEffect(()=>{
        updateSinglePhoto && updateSelectedPhotos()
    },[updateSinglePhoto])
    
    useEffect(() => {
        (tab==photos || faceSearchOnly || tab==guestUpload || tab==albumSelection || tab==userFolders) && fetchImages(); 
    }, [hdUrl, collection, selectedOption, faceSearchOn, faceSearchData, yourUploads]);
   
    useEffect(() => {
        getBookingData()
    }, [id, shared_key]);

    useEffect(() => {
        const handlePopState = (event) => {  //This function will handle browser back navigation
            if(faceSearchOn && !faceSearchOnly){
                if(!showGallery){
                    exitFaceSearch()
                }
            }else{
                if(!videoStatus && !albumStatus && !showGallery && !faceSearchState && !faceSearchOnly){
                    !window.location.hash && navigate(`/?id=${id}`)
                }
            }
            if(showGallery){
                !window.location.hash && closeGalleryHandler()
            }
        };
        
        window.addEventListener('popstate', handlePopState);
    
        return () => {
          window.removeEventListener('popstate', handlePopState);
        };
    }, [videoStatus, albumStatus, showGallery, faceSearchState, faceSearchOn, reloadDelete]);
      
    useEffect(() => {
        if(tab && !collection_id && data){
            handleTabClick(tab)
        }
        else if((!tab || !collection_id) && data) {
            if (data.data?.['photo-collection']) {
                handleTabClick(photos);
            } else if (data.data?.['video']) {
                handleTabClick(videos);
            } else if (data.data?.['album']) {
                handleTabClick(albums);
            } else if (data.data?.['guest-gallery']){
                handleTabClick(guestUpload)
            }
        }else if(tab && collection_id && data){
            handleCollections(collection_id)
        }
        var processedData = {}
        if (data?.data?.video) {
            data.data.video.forEach((e) => {
                processedData[e.work_id] = e.is_hidden;
            });
        }
        if (data?.data?.album) {
            data.data.album.forEach((e) => {
                processedData[e.work_id] = e.is_hidden;
            });
        }
        setHiddenWork(processedData);
    }, [data]);

    useEffect(()=>{
        (tab==guestUpload) && fetchImages()
    },[allGuestPhotos])

    useEffect(() => {
        if (pastFaceSearches && pastFaceSearches.length > 0) {
          pastFaceSearches.forEach((ele, index) => {
            cropImage(ele.img_url, ele.bounding_box, index);
          });
        }else{
            setLoading(false)
        }
    }, [pastFaceSearches]);

    useEffect(()=>{
        if(tab==guestUpload){
            getFilteredData()
        }
    },[yourUploads])

    useEffect(() => {
        setShowTooltip(true);
        const timer = setTimeout(() => {
          setShowTooltip(false);
        }, 15000);
    
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (filterDropdown || shareDropdown || moreDropdown || likeViewPopup) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [filterDropdown, shareDropdown, moreDropdown, likeViewPopup]);

    useEffect(()=>{
        if(data){
            const initial = true
            getLikesData(initial)
        }
    },[data])

    useEffect(() => {
        const storedHistory = JSON.parse(localStorage.getItem('nav_history')) || [];
        tab==userFolders ? setRedirectUrl(storedHistory) : setNavigationHistory(location, currentPage, data)
    }, [location, data]);


    return (
        <>
            {isLoggedin && (data || faceSearchData) && !faceOnly ? 
                <>
                    <Navbar location={"/workDetails"} title={``}/>
                    {<Popup
                    isOpen={isPopupOpen}
                    onClose={() => {
                        setIsPopupOpen(false);
                    }}
                    >
                        <div className="downloadDetails">
                            {downloadDetails?.download_title &&<h3>{downloadDetails.download_title}</h3>}
                            <div className="downloadData" style={{display: !downloadDetails?.size_msg && 'none'}}>
                                The size of selected files is approx <span style={{ fontWeight: "900" }}>{downloadDetails?.size_msg}</span>.</div>
                            <Link to={`${downloadDetails?.link}`} target="_blank" style={{display:"flex", justifyContent:"center"}}>
                                <button className="shareWp" style={{backgroundColor:get_platform_bg_color(), border:'none'}}>
                                    <img style={{height: currentDimensions > 600 ? "22px" : "20px"}} src={downloadIcon} className="invert-image" alt="download"/>
                                Download Zip
                                </button>
                            </Link>
                            <div className="cpyAlbum" style={{backgroundColor: "rgba(0,0,0,0.7)", margin:"7px auto 14px auto"}}>
                                <FontAwesomeIcon
                                    icon={faCopy}
                                    style={{ color: "#ffffff" }}
                                />
                                <p onClick={()=>copyLinkUrl(downloadDetails?.link)}>Copy Zip Link</p>
                            </div>
                            <Link to={`${getWhatsappShareUrl(downloadDetails?.link)}`} target="_blank">
                                <button className="shareWp">
                                    <FontAwesomeIcon
                                    icon={faWhatsapp}
                                    style={{ color: "#ffffff" }}
                                    size="lg"
                                    /> Share Zip Link
                                </button>
                            </Link>
                        </div>
                    </Popup>}
                    {<Popup
                        isOpen={lockPopup}
                        onClose={() => {
                            setLockPopup(false);
                        }}>
                            <div style={{textAlign:"left", padding:"10px", maxWidth: "500px"}}>
                                <h3>{collection?.lock_dialog?.title}</h3>
                                <div style={{padding:"10px 0"}}>{collection?.lock_dialog?.message}</div>
                                <div style={{display:"flex", justifyContent:"flex-end", fontSize:"medium"}}>
                                    <button style={{border:"1px solid black", padding:"3px 5px", borderRadius:"10px"}} onClick={()=>setLockPopup(false)}>Cancel</button>
                                    <button style={{border:`1px solid ${get_platform_action_color()}`, color:"white", background: get_platform_action_color(), padding:"3px 5px", borderRadius:"10px", display:"flex", margin: "0 10px", alignItems:"center"}} 
                                    onClick={()=>{setLockPopup(false); lockAlbum()}}>
                                        <img className="invert-image" style={{width: '18px', marginRight:"4px", minWidth:"18px", height:"18px"}} src={lockIcon}/>{" "}Lock
                                    </button>
                                </div>
                            </div>
                    </Popup>}
                    {updateLoader && <div className="overlayLoader">{globalLoader()}</div>}
                    <div style={{display: workIdError && "none"}}>
                        {scrollPosition > 500 && <div className="scrollToTop" onClick={getToTop}><FontAwesomeIcon icon={faArrowUp} size="lg" style={{color: "#ffffff",}} /></div>}
                        {albumStatus!=='true' && videoStatus!=='true' && <div style={{backgroundColor: currentDimensions > 1200 && get_platform_bg_color(), display: !showFilters && currentDimensions > 1200 && 'none'}} className= {`${!selection ? (currentDimensions > 1200 ? 'topSwitchTabs' : 'bottomSwitchTabs') : 'displayNone'} ${selection ? 'inactiveClass' : ''}`}>
                            {!shared_key && currentPage!==CONST.WORK_DETAILS && (data?.home_url || redirectUrl?.some(e=>e.navtype==CONST.HOME_URL) || redirectUrl?.some(e=>e.navtype==CONST.WORK_DETAILS)) && 
                            <p className={currentDimensions<=1200 ? "tabsPara" : ''} style={{position: currentDimensions > 1200 && "absolute", left: "60px", cursor:"pointer", transform: tab==guestUpload && currentDimensions>1200 && 'translateX(-50%)', paddingTop:"10px"}} onClick={()=>{tab==userFolders ? handleBackNavigation(redirectUrl?.find(e=>e.navtype==CONST.HOME_URL  || redirectUrl?.find(e=>e.navtype==CONST.WORK_DETAILS))) : redirectHome()}}>
                                <img className={currentDimensions>1200 ? "invert-image" : ""} src={homeIcon} style={{marginRight:currentDimensions>600 && "5px", maxWidth:"25px"}}/>
                                {currentDimensions<=1200 && <span>Home</span>}
                                {data?.home_url?.tooltip && <span className="tooltip">
                                    <span className={`tooltiptext homeTip ${showTooltip ? 'show' : hide}`}>{data?.home_url?.tooltip}</span>
                                </span>}
                            </p>}   
                            {data?.data && Object.keys(data?.data).map((key, index) => (
                                <React.Fragment key={index}>
                                    {key === 'photo-collection' && <p className="tabsPara" onClick={()=>handleTabClick(photos)} style={{borderBottom: tab==photos && (currentDimensions >1200 ? '3px solid white' : '3px solid black')}} key={index}>
                                            <img className={currentDimensions > 1200 ? "invert-image" : ""} src={imageIcon} style={{paddingRight : currentDimensions >600 && "5px"}}/>
                                            <span>Photos</span>
                                        </p>}
                                    {key === 'video' && <p className="tabsPara" onClick={()=>handleTabClick(videos)} style={{borderBottom: tab==videos && (currentDimensions >1200 ? '3px solid white' : '3px solid black')}} key={index}>
                                            <img className={currentDimensions > 1200 ? "invert-image" : ""} src={videoIcon} style={{paddingRight : currentDimensions >600 && "5px"}}/>
                                            <span>Videos</span>
                                        </p>}
                                    {key === 'album' && <p className="tabsPara" onClick={()=>handleTabClick(albums)} style={{borderBottom: tab==albums && (currentDimensions >1200 ? '3px solid white' : '3px solid black')}} key={index}>
                                            <img className={currentDimensions > 1200 ? "invert-image" : ""} src={albumIcon} style={{paddingRight : currentDimensions >600 && "5px"}}/>
                                            <span>Albums</span>
                                        </p>}
                                </React.Fragment>
                            ))}
                            {tab === guestUpload && <p className="tabsPara" onClick={()=>guestUploadTab(all)} style={{borderBottom: allGuestPhotos && (currentDimensions >1200 ? '3px solid white' : '3px solid black')}} >
                                <img className={currentDimensions > 1200 ? "invert-image" : ""} src={allUploadIcon} style={{paddingRight : currentDimensions >600 && "5px", width:"32px"}}/> 
                                <span >All Uploads</span>
                            </p>}
                            {tab === guestUpload && <p className="tabsPara" onClick={()=>guestUploadTab()} style={{borderBottom: !allGuestPhotos && (currentDimensions >1200 ? '3px solid white' : '3px solid black')}} >
                                <img className={currentDimensions > 1200 ? "invert-image" : ""} src={myUploadIcon} style={{paddingRight : currentDimensions >600 && "5px", width:"32px"}}/> 
                                <span >My Uploads</span>
                            </p>}
                            {faceSearchOnly && <p className="tabsPara" style={{borderBottom: (currentDimensions >1200 ? '3px solid white' : '3px solid black')}}>
                                <img className={currentDimensions > 1200 ? "invert-image" : ""} src={imageIcon} style={{paddingRight : currentDimensions >600 && "5px"}}/>
                                <span>Photos</span>
                            </p>}
                            {(tab==albumSelection || redirectUrl?.some(e=>e.navtype==CONST.ALBUM_SELECTION)) && <p className="tabsPara" style={{borderBottom: tab==albumSelection && (currentDimensions >1200 ? '3px solid white' : '3px solid black')}} onClick={()=>handleBackNavigation(redirectUrl?.find(e=>e.navtype==CONST.ALBUM_SELECTION))}>
                                <img className={currentDimensions > 1200 ? "invert-image" : ""} src={albumSelectionIcon} style={{paddingRight : currentDimensions >600 && "5px", width:currentDimensions>600 && '32px'}}/>
                                <span>Album Selection</span>
                            </p>}
                            {redirectUrl?.some(e=>e.navtype==CONST.DASHBOARD) && <p className="tabsPara"  onClick={()=>handleBackNavigation(redirectUrl?.find(e=>e.navtype==CONST.DASHBOARD))}>
                                <img className={currentDimensions>1200 ? "invert-image" : ""} src={bookingIcon} style={{marginRight:currentDimensions>600 && "5px", maxWidth:"25px"}} />
                                <span>Bookings</span>
                            </p>}
                            {redirectUrl?.some(e=>e.navtype==CONST.SHARED_LINKS) && <p className="tabsPara" onClick={()=>handleBackNavigation(redirectUrl?.find(e=>e.navtype==CONST.SHARED_LINKS))} >
                                <img className={currentDimensions>1200 ? "invert-image" : ""} src={linkIcon} style={{maxWidth:"25px"}} />
                                <span>Shared Links</span>
                            </p>}
                            {tab==userFolders && redirectUrl?.some(e=>e.navtype==CONST.GUEST_UPLOADS) && <p className="tabsPara" onClick={()=>handleBackNavigation(redirectUrl?.find(e=>e.navtype==CONST.GUEST_UPLOADS))} >
                                <img className={currentDimensions>1200 ? "invert-image" : ""} src={uploadIcon} style={{marginRight:currentDimensions>600 && "5px", maxWidth:"25px", width:"auto"}} />
                                <span>Guest Uploads</span>
                            </p>}
                            {<p className="tabsPara" onClick={()=>{tab!==userFolders && handleLibrary(location, currentPage, data)}} style={{borderBottom: tab==userFolders && (currentDimensions >1200 ? '3px solid white' : '3px solid black')}}>
                                <img className={currentDimensions>1200 ? "invert-image" : ""} src={libraryIcon} style={{marginRight:currentDimensions>600 && "5px", maxWidth:"25px", width:"auto"}} />
                                <span>Library</span>
                            </p>}
                            {tab!==guestUpload && data?.data?.more?.length>0 && <div ref={moreRef} style={{position:"relative", margin: '0 0.5rem', cursor:"pointer", display:currentDimensions>600 && "flex", alignItems:"center"}} onClick={()=>moreMenu()}>
                                <GiHamburgerMenu style={{width: currentDimensions>600 ? "37px" : '30px', height:currentDimensions>600 ? "35px" : '30px', paddingTop:"10px"}}/><p style={{paddingTop:currentDimensions>600 && "10px", fontSize: currentDimensions<600 && '10px'}}>More</p>
                                {moreDropdown && <div className={`moreDropdown ${currentDimensions<600 ? 'drawer open' : ""}`}>
                                    {currentDimensions<600 && <div className="moreNav">
                                        <div style={{width:"20px"}}></div>
                                        <div style={{fontWeight:"600"}}>More</div>
                                        <div><FontAwesomeIcon icon={faXmark} size="lg" style={{cursor:"pointer"}}/></div>
                                    </div>}
                                    {data?.data?.more.map((item)=>(
                                        Object.entries(item).map(([key, value]) => (
                                            <div className="moreOption" key={key} onClick={()=>handleGuestUplaods(value)}><p className="moreImgTag"><img style={{height:"18px"}} src={get_more_logo(key)}/></p>{value.title}</div>
                                          ))
                                        )
                                    )}
                                </div>}
                            </div>}
                        </div>}
                        {!faceSearchState ? <><div className={showFilters ? "filterOptions" : "hideFilters"} style={{paddingTop: !selection ? (currentDimensions <= 1200 ? (currentDimensions <= 600 ? "65px" : "75px") : "150px") : (currentDimensions <= 600 ? '55px' : '70px'), paddingBottom:"10px", backgroundColor: currentDimensions <= 1200 && get_platform_bg_color(), color: currentDimensions < 1200 && 'white'}}>
                            <div style={{display:"flex", alignItems:"center"}}><div className={selection ? 'displayNone' : "tooltip"}>{data?.can_face_search && (tab==photos || tab==guestUpload || tab==albumSelection)  && <div className="faceFilterDiv" onClick={()=>{faceSearchOn ? exitFaceSearch() : faceFilterPhoto()}} style={{border: `${shared_key ? currentDimensions <1200 && '1px solid white' : 'none'}`}}>
                                <img className={`${currentDimensions < 1200 ? "invert-image" : ""} ${(selection || faceSearchOn) && 'displayNone'}`} src={selfieIcon} style={{width: currentDimensions > 600 ? "30px" : "22px", maxHeight:"30px"}}/>
                                {<p>
                                    {faceSearchOn ? (
                                        <span><FontAwesomeIcon icon={faArrowLeft} style={{color: currentDimensions > 1200 ? "#000000" : "#ffffff",}} /> Back</span>
                                    ) : (
                                        <span>{shared_key && 'My Photos'}</span>
                                    )}
                                </p>}
                            </div>}
                            {tab==photos && data?.can_face_search && <span className={`tooltiptext ${showTooltip ? 'show' : hide}`}>Use this to filter photos by your face</span>}</div>
                            {document.getElementsByClassName('my-masonry-grid_column').length!==0 && (tab==photos || tab==guestUpload) && get_global_can_make_private(faceSearchOn ? faceSearchData : collection) &&  <div id='hideButton' onClick={()=>getSelectionData(hide)} style={{display: selection && "none"}}>
                                <img src={currentDimensions > 1200 ? hideIcon : whiteHideIcon}/>
                            </div>}
                            {document.getElementsByClassName('my-masonry-grid_column').length!==0 && (tab==photos || tab==guestUpload || tab==albumSelection || faceSearchOnly || tab==userFolders) && ((!faceSearchOn ? (get_global_is_downloadable(collection) && data?.selective_download) : (get_global_is_downloadable(faceSearchData) && faceSearchData?.selective_download)) || faceSearchOnly) && <div id='allDownloadButton' className={
                                faceSearchOn
                                ? (shared_key ? (faceSearchData?.public_photo_count>0 ? '' : 'displayNone') : ((getPhotoCountFaceSearch()>0) ? '' : 'displayNone'))
                                : (shared_key ? (tab!==guestUpload ? 'displayNone' : '') : ((collection?.photo_count>0) ? '' : 'displayNone')) 
                            } onClick={downloadMethod} style={{display: (faceSearchOn && !get_global_is_downloadable(faceSearchData)) || selection && 'none'}}>
                                <img style={{height: currentDimensions > 600 ? "25px" : "18px", width: currentDimensions > 600 ? "25px" : "18px",}} src={downloadIcon} className={currentDimensions < 1200 ? "invert-image" : ""} alt="download"/>
                            </div>}
                            {document.getElementsByClassName('my-masonry-grid_column').length!==0 && (tab==guestUpload || tab==albumSelection) && !selection && !faceSearchOn && (allGuestPhotos ? (tab!==albumSelection ? collection?.admin_delete : (collection?.album_delete && !albumLocked)) : collection?.delete) && <div className="deleteDiv" style={{display: images?.length==0 && tab!==albumSelection && "none", cursor:"pointer"}} onClick={()=>getSelectionData(deleteUploads)}><img src={deleteIcon} style={{width:currentDimensions < 600 ? "22px" : "28px"}} className={currentDimensions<1200 ? 'invert-image' : ""} alt='delete'/></div>}
                            </div>
                            <div className={selection ? 'displayNone' : ' '} style={{display:"flex", alignItems: "center"}}>
                                {document.getElementsByClassName('my-masonry-grid_column').length!==0 && collectionData && albumData && Object.keys(albumData).length>0 && <div className={
                                    faceSearchOn
                                    ? ((get_global_can_add_to(faceSearchData) && get_global_is_album_required(faceSearchData)) ? '' : "displayNone")
                                    : ((get_global_can_add_to(collection) && get_global_is_album_required(collection)) ? '' : 'displayNone')
                                    } onClick={()=>getSelectionData(addTo)} style={{marginRight: currentDimensions > 600 ? "25px" : "15px", border: currentDimensions > 1200 ? "1px solid black" : "1px solid white", padding: "5px", borderRadius: "1vh", cursor: "pointer", display: tab!==photos && tab!==albumSelection && tab!==guestUpload && 'none' }}>
                                    <FontAwesomeIcon icon={hollowCheck} style={{color: currentDimensions > 1200 ? '#000000' : "#ffffff"}}/> Select
                                </div>}
                                {collection?.is_upload_allowed && !faceSearchOn && <div><button onClick={indirectUpload} className="uploadButton" style={{backgroundColor:get_platform_action_color()}}><img style={{width: currentDimensions<600 ? '20px' : "25px", marginRight:"5px"}} className={currentDimensions <1200 ? '' : "invert-image"} src={uploadIcon}/>{" "}Upload</button></div>}
                                {data?.is_shareable && <div ref={shareRef} className="shareMediaDiv" onClick={()=>!contentLoading && shareMedia()} style={{display : faceSearchOn && 'none', background: currentDimensions > 1200 ? get_platform_bg_color() : 'white', color:currentDimensions > 1200 ? "white" : "black"}}>
                                    <img className={currentDimensions < 1200 ? '' : "invert-image"} style={{width: currentDimensions < 1200 && '18px', marginRight:"7px"}} src={shareIcon} /> {" "}Share<>
                                    {shareDropdown && <div className="shareDropdown">
                                        <div className="shareOption" onClick={()=>{!isAdmin() && shareDeliverable('all')}}>Share Everything</div>
                                        <div className="shareOption" onClick={()=>shareDeliverable('selective')}>Share Selectively</div>
                                        <div className="shareOption" onClick={()=>{!isAdmin() && shareDeliverable('face_only')}}>Face Search Only</div>
                                </div>}</></div>}
                                {tab==albumSelection && <div className="lockDiv" onClick={()=>!albumLocked && setLockPopup(true)} style={{display : faceSearchOn && 'none', background: currentDimensions > 1200 ? get_platform_bg_color() : 'white', color:currentDimensions > 1200 ? "white" : "black"}}>
                                    <img className={currentDimensions < 1200 ? '' : "invert-image"} style={{width: currentDimensions < 1200 && '18px', marginRight:"4px"}} src={lockIcon}/>{" "}{albumLocked ? 'Locked' : 'Lock'}
                                </div>}
                            </div>
                            {(selection) && <div style={{width:"inherit", display:"flex", justifyContent:"space-between"}}>
                                    <div>
                                        {(downloadSelection && !get_global_is_downloadable(faceSearchOn ? faceSearchData : collection)) || (hideSelection && !get_global_can_make_private(faceSearchOn ? faceSearchData : collection)) ? <></> : (document.getElementsByClassName('my-masonry-grid_column').length!==0 && <button style={{border: currentDimensions < 1200 ? '1px solid white' : "1px solid black", padding:"5px 12px", borderRadius:"5px", marginRight:"10px", marginTop:"10px"}} onClick={()=>toggleSelection()}><FontAwesomeIcon icon={selectionAll[collection_id] ? faCircleCheck : hollowCheck} style={{color: currentDimensions < 1200 ? "#fff" : "#000000",}} /> Select all</button>)}
                                    </div>
                                    <div style={{display:"flex"}}>
                                        <button style={{border: currentDimensions < 1200 ? '1px solid white' : "1px solid black", padding:"5px 12px", borderRadius:"5px", marginRight:"10px", marginTop:"10px"}} onClick={()=>{!contentLoading && cancelSelection()}}>Discard</button>
                                        {selection && (hideSelection || downloadSelection || deleteSelection) && <button id="hideDiv" style={{display: addToSelection && 'none', backgroundColor: get_platform_action_color()}} onClick={()=>{!contentLoading && updateSelectedPhotos()}}>Update</button>}
                                        {addToSelection && <button id="addToDiv" style={{backgroundColor: get_platform_action_color(), marginRight:"10px", marginTop:"10px"}} onClick={toggleAddToPopup}>Add To</button>}
                                        {selection && addToSelection && downloadSelection && <button id="downloadDiv" style={{marginRight:"10px", marginTop:"10px"}} onClick={updateSelectedPhotos}>
                                            <img style={{height: currentDimensions > 600 ? "25px" : "20px"}} src={downloadIcon} className={currentDimensions < 1200 ? "invert-image" : ""} alt="download"/>
                                        </button>}
                                        {<Popup
                                        isOpen={isAddToOpen}
                                        onClose={()=>{setIsAddToOpen(false);
                                                    setCollection();
                                                    setCollectionData();
                                                    setRecallCollection(true);
                                        }}
                                        >
                                            <div
                                            ref={popupRef}
                                            style={{
                                                minWidth: currentDimensions > 600 ? "35vw" : "80vw",
                                                maxWidth: currentDimensions > 600 ? "35vw" : "80vw",
                                                padding: "20px",
                                                textAlign: "center",
                                                color: "black"
                                            }}
                                            >
                                            <h3 style={{ margin: "0 0 10px" }}>Add to Album</h3>
                                            <ul style={{ listStyle: "none", padding: 0 }}>
                                                {(faceSearchOn ? faceSearchData : collection)?.album_data?.album_data && Object.entries((faceSearchOn ? faceSearchData : collection).album_data['album_data']).map(([albumKey, myAlbumData]) => (
                                                    <li
                                                        key={albumKey}
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                            padding: "10px",
                                                            borderBottom: "1px solid #ddd",
                                                            cursor: "pointer",
                                                            marginRight:"10px"
                                                        }}
                                                    >
                                                    <a href={`${myAlbumData.album_url}`} target="_blank" size='xs' style={{marginRight: '10px'}}><FontAwesomeIcon style={{color: 'gray'}} icon={faExternalLinkAlt} /></a>
                                                    |
                                                    <label
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                            width: "100%",
                                                            cursor: "pointer",
                                                            marginLeft: "10px",
                                                        }}
                                                    >
                                                      <span>{myAlbumData.title}</span>
                                                
                                                      {albumData && !albumData[albumKey]?.is_locked ? <input
                                                            type="checkbox"
                                                            style={{
                                                                cursor: "pointer",
                                                                accentColor: get_platform_bg_color()
                                                            }}
                                                            onChange={(event) => {
                                                                const isChecked = event.target.checked;
                                                                updateAlbum(albumKey, isChecked)
                                                            }}
                                                            disabled={albumData && albumData[albumKey]?.is_locked || isAdmin()}
                                                      /> : <span style={{ fontStyle: 'italic', color: 'gray', fontSize: 'medium' }}>Locked</span>}
                                                    </label>
                                                  </li>
                                                ))}
                                            </ul>
                                            </div>
                                            <button
                                                style={{
                                                    marginRight: "2vw",
                                                    padding: "10px 20px",
                                                    backgroundColor: get_platform_action_color(),
                                                    border: "none",
                                                    borderRadius: "5px",
                                                    color: "white",
                                                    cursor: "pointer",
                                                }}
                                                onClick={addToDone}
                                            >
                                                Done
                                            </button>
                                        </Popup>}
                                        
                                    </div>
                                </div>}
                        </div>
                        {(tab==photos || faceSearchOnly || tab==guestUpload || tab==albumSelection || userFolders) && <div style={{display: "flex", justifyContent:"center", alignItems:"center", paddingTop:currentDimensions < 1200 || selection ? "130px" : "220px"}}>
                            {showArrow && currentDimensions>1200 && !faceSearchOn && <div id='leftArrow' style={{cursor:"pointer"}} onClick={()=>scrollStory('left')}><FontAwesomeIcon icon={faChevronLeft} size="lg" style={{color: "#000000", padding:"0 10px"}} /></div>}
                            <div id='storyNav' ref={storyRef} style={{display: data?.data && (getSelectedData())?.length>1 ? "flex" : 'none', overflow: "auto"}}>
                                {data?.data && (getSelectedData())?.map((e, index)=>(
                                <div onClick={()=>handleCollections(e.work_id)} key={index} className={currentDimensions < 1200 ? `storyDiv ${contentLoading && 'inactiveClass'}` : `pillStoryDiv ${contentLoading && 'inactiveClass'}`} style={{background: e.work_id == collection_id && currentDimensions > 1200 && get_platform_bg_color(), color: e.work_id == collection_id && currentDimensions > 1200 && 'white', boxShadow: e.work_id == collection_id && currentDimensions > 1200 && "0 -2px 10px rgba(0, 0, 0, 0.4)", display: faceSearchOn && 'none', margin: `15px ${pillMargin}px`}}>
                                    <img className={currentDimensions < 1200 ? `imgStory ${e.work_id == collection_id && 'openClass'}` : `pillImgStory ${e.work_id == collection_id && 'openClass'}`} style={{border: e.work_id == collection_id && currentDimensions <1200 && `3px solid ${get_platform_bg_color()}`}} src={e?.cover_image}/>
                                    <div style={{display:"flex", flexDirection:"column"}}>{splitTitle(tab==albumSelection && e.work_id == collection_id && albumTitle ? albumTitle : e.title).map((e, index)=>{
                                        return (
                                            <span style={{whiteSpace:"nowrap"}} key={index}>{e.length>11 ? e.slice(0,8)+'...' : e}</span>
                                        )
                                    })}</div>
                                </div>
                            ))}</div>
                            {faceSearchOn && <div style={{width:"100%", display:"flex", justifyContent:"left", padding:"10px 20px"}}>
                                <button className='retrySearch' style={{display: selection && 'none'}} onClick={()=>faceSearch()}><FontAwesomeIcon icon={faRotateRight} style={{color: "#000000"}} /> Retake</button>
                            </div>}
                            {showArrow && !faceSearchOn && currentDimensions>1200 &&  <div id='rightArrow' onClick={()=>scrollStory('right')} style={{cursor:"pointer"}}><FontAwesomeIcon icon={faChevronRight} size="lg" style={{color: "#000000", padding:"0 10px"}} /></div>}
                        </div>}
                        {tab==albums && albumStatus!=='true' && <div style={{height: data?.data?.['album']?.length == 1 && 0.7*window.innerHeight, gridTemplateColumns: data?.data?.['album']?.length == 1 && 'repeat(1, 1fr)'}} className="albumGrid">
                            {data.data['album'] && data.data['album'].map((e, index)=>(
                                <div key={index} id={e.work_id}  className="albumStoryDiv" onClick={()=>handleAlbum(e.work_id)}>
                                    {data?.can_make_private && hiddenWork && (
                                    <div onClick={(event) => { event.stopPropagation(); hideSelected(e.work_id, hiddenWork[e.work_id]);}} style={{ position: 'absolute', top: '10px', left: '10px', width: '50px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', }}>
                                    <img style={{ width: '35px', filter: hiddenWork[e.work_id]? 'drop-shadow(0px 0px 3px red)': 'drop-shadow(0px 0px 3px white)', }} src={ hiddenWork[e.work_id]?'https://assets.graphia.in/site_media/webapp/red_eye.png':'https://assets.graphia.in/site_media/webapp/white_eye.png'} alt={hiddenWork[e.work_id] ? 'Hidden Icon' : 'Visible Icon'}/>
                                    </div>)}
                                    <img style={{objectFit: "cover", height: "75%", width:"100%", borderRadius:"2%"}} src={e.cover_image} />
                                    <span style={{fontSize:currentDimensions>600 ? "1.3rem" : "1.1rem", height:"25%", display:"flex", alignItems:"center"}}>{e.title.length>50 ? e.title.slice(0,49)+'...' : e.title} {" "} </span>
                                </div>
                            ))}
                        </div>}
                        {tab==videos && videoStatus!=='true' && <div style={{height: data?.data?.['video']?.length == 1 && 0.7*window.innerHeight, gridTemplateColumns: data?.data?.['video']?.length == 1 && 'repeat(1, 1fr)'}} className="videoGrid">
                            {data?.data?.['video'] && data?.data?.['video']?.map((e, index)=>(
                                <div key={index} id={e.work_id} className="videoStoryDiv" onClick={()=>handleVideo(e.work_id)}>
                                    {data?.can_make_private && hiddenWork && (
                                    <div onClick={(event) => { event.stopPropagation(); hideSelected(e.work_id, hiddenWork[e.work_id]);}} style={{ position: 'absolute', top: '10px', left: '10px', width: '50px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', }}>
                                    <img style={{ width: '35px', filter: hiddenWork[e.work_id]? 'drop-shadow(0px 0px 3px red)': 'drop-shadow(0px 0px 3px white)', }} src={ hiddenWork[e.work_id]?'https://assets.graphia.in/site_media/webapp/red_eye.png':'https://assets.graphia.in/site_media/webapp/white_eye.png'} alt={hiddenWork[e.work_id] ? 'Hidden Icon' : 'Visible Icon'}/>
                                    </div>)}
                                    <div style={{position:"absolute", top:"40%", left:"50%"}}><FontAwesomeIcon icon={faPlay} style={{color: "#ffffff",}} size="xl"/></div>
                                    <img style={{objectFit: "cover", height: "75%", width:"100%", borderRadius:"2%"}} src={e.thumb_url}/>
                                    <div style={{display:"flex", alignItems:"center", justifyContent:e.is_downloadable ? "space-between" : 'center', width:"90%", height:"25%"}}><span style={{fontSize: currentDimensions>600 ? "1.2rem" : "18px", marginRight:"5px"}}>{e.title.length>50 ? e.title.slice(0,49)+'...' : e.title} {" "} </span><span>
                                        {e.is_downloadable && <div onClick={(event)=>{event.stopPropagation();qualityPopup(e.url_list)}} className="cardDownload">Download</div>}
                                    </span></div>
                                </div>
                            ))} 
                        </div>}
                        {(tab==photos || tab==guestUpload || faceSearchOnly || tab==albumSelection || tab==userFolders) && collection && <div ref={divRef} style={{fontSize: "large", fontWeight: "600", padding: "0 2% 10px 2%", display:"flex", justifyContent: (shared_key || (tab==userFolders && userFolderData?.length>0 && userFolderData[0]?.private_data?.length==0)) ? "center" : "space-between", alignItems:"center"}}>
                            {!(tab==userFolders && userFolderData?.length>0 && userFolderData[0]?.private_data.length==0) && <div style={{width: currentDimensions>900 ? '125px' : '85px', display: shared_key && 'none'}}></div>}
                            <div style={{maxWidth:currentDimensions < 600 && "70%", fontSize:currentDimensions < 600 && '15px', display:"flex", alignItems:"center", flexDirection:"column"}}>
                                {(tab==guestUpload || shared_key) && <div>{data?.booking_name}</div>}
                                {isEditing ? <div style={{display:"flex"}}><input
                                                type="text"
                                                value={albumTitle}
                                                onChange={handleTitleChange}
                                                style={{padding:"0 10px", width:"180px", WebkitBorderBottomLeftRadius:"10px", borderTopLeftRadius:"10px", textAlign:"center", border: `1px solid ${get_platform_action_color()}`}}
                                                autoFocus
                                                className="border-b-2 border-blue-500 outline-none"
                                            /><div onClick={changeAlbumTitle} style={{display:"flex", alignItems:"center", background: get_platform_action_color(), padding:"5px", borderTopRightRadius:"10px", borderBottomRightRadius:"10px"}}><img className="invert-image" src={checkIcon} style={{width:"18px", minWidth:"18px"}}/></div></div> : 
                                            <div style={{fontSize:tab!==albumSelection && "smaller", fontWeight:tab==albumSelection ? '900' : "100", fontStyle:"italic", display:"flex"}}>{!faceSearchOn && (tab==albumSelection ? albumTitle : collection?.title)}{faceSearchOn && images && `${faceSearchData?.photo_count} Photos Matched`} {tab==albumSelection && <p onClick={()=>setIsEditing(true)} style={{ paddingLeft:"10px", display:"flex", alignItems:"center"}}><img style={{width: "15px", cursor:"pointer"}} src={pencilIcon}/></p>}</div>}
                                            {(tab==albumSelection || tab==userFolders) && <div style={{fontSize:"small",color:"grey", fontWeight:"100", display:"flex"}}>{collection?.subtitle}</div>}
                            </div>
                            {!shared_key && <div ref={dropdownRef} style={{display: (tab==userFolders && userFolderData.length>0 && userFolderData[0]?.private_data.length==0) ? 'none' : 'flex'}}>
                                <div 
                                className={`${contentLoading ? 'inactiveClass' : ''}`} 
                                style={{
                                    display: 'flex', 
                                    alignItems: "center", 
                                    position: "relative", 
                                    cursor: "pointer", 
                                    padding: "0 10px", 
                                    visibility: selection && 'hidden', 
                                    fontSize: currentDimensions>600 ? "18px" : '15px', 
                                    fontWeight: "normal", 
                                }} 
                                onClick={() => {
                                    setFilterDropdown((prev) => !prev);
                                }}
                                >
                                <img style={{ height: currentDimensions>600 ? "25px" : "18px" }} src={filter} alt="Filter Icon" />
                                <p style={{ display: currentDimensions < 900 && 'none', marginLeft: '5px' }}>Filter</p>
                                {!faceSearchOn ? 
                                    (collection && `(${selectedOption == allPhotos ? (allGuestPhotos ? collection?.photo_count : yourUploads.length) : galleryImages?.length})`) : 
                                    `(${getPhotoCountFaceSearch()})`}
                                {selectedOption !== allPhotos && 
                                    <sup>
                                    <div className="dot" style={{ backgroundColor: get_platform_bg_color() }}></div>
                                    </sup>}
                                    {filterDropdown && (
                                <div
                                    style={{
                                    position: "absolute",
                                    top: `30px`,
                                    right: "10px",
                                    border: "1px solid #ccc",
                                    backgroundColor: "#fff",
                                    padding: "0 10px",
                                    borderRadius: "4px",
                                    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
                                    zIndex: 1,
                                    width: "max-content",
                                    fontWeight: "100",
                                    fontSize: currentDimensions<600 && '15px'
                                    }}
                                >
                                    <label onClick={() => handleFilterCheckboxChange(allPhotos)} htmlFor="all" style={{ display: "flex", justifyContent: "flex-start", alignItems:"center", margin:"7px" }}>
                                    <input
                                        id="all"
                                        type="checkbox"
                                        checked={selectedOption == allPhotos}
                                        onChange={() => handleFilterCheckboxChange(allPhotos)}
                                        style={{ marginRight: "10px", accentColor: get_platform_bg_color(), marginTop:"0px" }}
                                    />
                                    All Photos{` (${faceSearchOn ? faceSearchData?.photo_count : (allGuestPhotos ? collection?.photo_count : yourUploads.length)})`}
                                    </label>
                                    <hr />
                                    <label onClick={() => handleFilterCheckboxChange(privatePhotos)} htmlFor="private" style={{ display: "flex", justifyContent: "flex-start", alignItems:"center", margin:"7px" }}>
                                    <input
                                        id="private"
                                        type="checkbox"
                                        checked={selectedOption == privatePhotos}
                                        onChange={() => handleFilterCheckboxChange(privatePhotos)}
                                        style={{ marginRight: "10px", accentColor: get_platform_bg_color(), marginTop:"0px" }}
                                    />
                                    Hidden{hideSelectedObj && ` (${faceSearchOn ? Object.values(faceHide).reduce((sum, arr) => sum + arr.length, 0) : (allGuestPhotos ? Object.keys(collectionFormattedData).reduce((sum, collectionId) => {
                                                                                                                                                            const length = hideSelectedObj[collectionId]?.length || 0;
                                                                                                                                                            return sum + length;
                                                                                                                                                        }, 0) : yourUploads.reduce((count, item) => {
                                                                                                                                                            const exists = hideSelectedObj[collection_id].some(other => other.photo_id === item.photo_id);
                                                                                                                                                            return count + (exists ? 1 : 0);
                                                                                                                                                          }, 0))})`}
                                    </label>
                                    <hr />
                                    <label onClick={() => handleFilterCheckboxChange(publicPhotos)} htmlFor="public" style={{ display: "flex", justifyContent: "flex-start", alignItems:"center", margin:"7px" }}>
                                    <input
                                        id="public"
                                        type="checkbox"
                                        checked={selectedOption == publicPhotos}
                                        onChange={() => handleFilterCheckboxChange(publicPhotos)}
                                        style={{ marginRight: "10px", accentColor: get_platform_bg_color(), marginTop:"0px" }}
                                    />
                                    Public{` (${faceSearchOn ? (Number(faceSearchData?.photo_count) - Number(Object.values(faceHide).reduce((sum, arr) => sum + arr.length, 0))) : ((allGuestPhotos ? collection?.photo_count : yourUploads.length) - (allGuestPhotos ? Object.keys(collectionFormattedData).reduce((sum, collectionId) => {
                                                                                                                                                                                                                                                    const length = hideSelectedObj[collectionId]?.length || 0;
                                                                                                                                                                                                                                                    return sum + length;
                                                                                                                                                                                                                                                }, 0): yourUploads.reduce((count, item) => {
                                                                                                                                                                                                                                                    const exists = hideSelectedObj[collection_id].some(other => other.photo_id === item.photo_id);
                                                                                                                                                                                                                                                    return count + (exists ? 1 : 0);
                                                                                                                                                                                                                                                  }, 0)))})`}
                                    </label>
                                </div>
                                )}
                                </div>
                                
                            </div>}
                        </div>}
                        {
                            (tab==videos && videoStatus=='true') && <div>
                                <div style={{position:"absolute", top:"20px", left:"20px", color:"white", zIndex:"201"}} onClick={()=>closeVideo()}>
                                    <FontAwesomeIcon icon={faXmark} size="xl" style={{color: "#ffffff",cursor:"pointer"}}/>
                                </div>
                                {(collection && videoUrl) ?
                                <div style={{position: "fixed", width: "100%", top: "0", zIndex: "200"}}><VideoPlayer data={collection} bookingPK={bookingPK} id={collection_id} videoUrl={videoUrl} /></div> :
                                <div className='overlayLoader' style={{background:"rgba(0,0,0,1)"}}>{globalLoader()}</div>
                            }
                            </div>
                        }
                        {(tab==photos || tab==guestUpload || tab==albumSelection || tab==userFolders || faceSearchOnly) && (faceSearchData?.photo_data || collection?.photo_data) ? <div style={{paddingBottom:currentDimensions < 600 ? "50%" : '10%'}}>
                            <div>
                            {(faceSearchOn ? faceSearchData?.photo_count > 0 : (allGuestPhotos ? collection?.photo_count > 0 : yourUploads.length > 0)) && (
                                ((!faceSearchOn) ? (tab==userFolders ? userFolderData : collection?.photo_data) : faceSearchData?.photo_data).map((ele, index) => (
                                    <div key={index}>
                                        {getDataByOption(ele, selectedOption)?.length > 0 && (faceSearchOn || tab==albumSelection) && (
                                            <div className="searchCollectionTitle">
                                                {ele.title}
                                            </div>
                                        )}
                                        <div style={{position:"relative", display: (((faceSearchOn ? faceHide[ele.work_id] : hideSelectedObj[ele.work_id])?.length==0 && selectedOption==privatePhotos) || ((ele._photo_count-(faceSearchOn ? faceHide[ele.work_id] : hideSelectedObj[ele.work_id])?.length)==0 && selectedOption==publicPhotos)) && 'none'}}>
                                            {(!(ele.can_add_to && ele.is_album_required) && addToSelection) && tab!==userFolders && (
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            background: "rgba(0,0,0,0.7)",
                                                            width: "100%",
                                                            height: "100%",
                                                            zIndex: 9,
                                                            bottom: 0,
                                                            color:"white",
                                                            display:"flex",
                                                            alignItems:"center",
                                                            justifyContent:"center",
                                                        }}
                                                    ><p style={{background:"rgba(0,0,0,0.9)", padding:"5px 10px", borderRadius:"5px"}}><FontAwesomeIcon icon={faCircleInfo} style={{color: get_platform_bg_color(),}} /> Collection can't be selected!</p></div>
                                            )}
                                            {(!(ele.can_make_private) && hideSelection) && tab!==userFolders && (
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            background: "rgba(0,0,0,0.7)",
                                                            width: "100%",
                                                            height: "100%",
                                                            zIndex: 9,
                                                            bottom: 0,
                                                            color:"white",
                                                            display:"flex",
                                                            alignItems:"center",
                                                            justifyContent:"center",
                                                        }}
                                                    ><p style={{background:"rgba(0,0,0,0.9)", padding:"5px 10px", borderRadius:"5px"}}><FontAwesomeIcon icon={faCircleInfo} style={{color: get_platform_bg_color(),}} /> Collection can't be hidden!</p></div>
                                            )}
                                            {(!(ele.is_downloadable) && downloadSelection) && tab!==userFolders && (
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            background: "rgba(0,0,0,0.7)",
                                                            width: "100%",
                                                            height: "100%",
                                                            zIndex: 9,
                                                            bottom: 0,
                                                            color:"white",
                                                            display:"flex",
                                                            alignItems:"center",
                                                            justifyContent:"center",
                                                        }}
                                                    ><p style={{background:"rgba(0,0,0,0.9)", padding:"5px 10px", borderRadius:"5px"}}><FontAwesomeIcon icon={faCircleInfo} style={{color: get_platform_bg_color(),}} /> Collection is not downloadable!</p></div>
                                            )}
                                            <Masonry
                                            key={index}
                                            breakpointCols={breakpointColumnsObj}
                                            className="my-masonry-grid"
                                            columnClassName="my-masonry-grid_column"
                                            style={{position:"relative"}}
                                            >
                                            {getDataByOption(ele, selectedOption)?.map((e, idx) => {
                                                const localIndex = currentIndex++;
                                                return (
                                                    <a style={{pointerEvents:!(ele.can_add_to && ele.is_album_required) && addToSelection && 'none'}} key={currentIndex} href={`#gallery?index=${currentIndex}`} onClick={()=>{!selection && setShowGallery(true)}}>
                                                        <div
                                                            id={`gallery${currentIndex}`}
                                                            style={{ position: "relative" }}
                                                            onClick={(event) => {
                                                            if (selection) {
                                                                event.preventDefault();
                                                                !e.processing && toggleSelectImage(e);
                                                            }
                                                            }}
                                                        >
                                                            <img
                                                            id={`${currentIndex}`}
                                                            style={{
                                                                display: "block",
                                                                width: "100%",
                                                                padding: "2.5px",
                                                            }}
                                                            src={e.thumb_url}
                                                            onClick={galleryHandler}
                                                            onLoad={() => handleImageLoad(localIndex)}
                                                            alt={`Thumbnail ${currentIndex}`}
                                                            />
                                                            {tab==guestUpload && loadedImages[localIndex] && <div id='guestName'>{(e?.user_full_name?.length>14 ? e.user_full_name.slice(0, 14)+'...' : e?.user_full_name)}</div>}
                                                            {selection && e?.processing && <div id='notDeleteOverlay'>
                                                                <p style={{background:"rgba(0,0,0,0.9)", padding:"5px 10px", borderRadius:"5px"}}><FontAwesomeIcon icon={faCircleInfo} style={{color: get_platform_bg_color(),}} /> Processing!</p>
                                                            </div>}
                                                            {tab==userFolders && selection && !get_liked_data_is_downloadable(collection, collectionFormattedData, e) && <div id='notDeleteOverlay'>
                                                                <p style={{background:"rgba(0,0,0,0.9)", padding:"5px 10px", borderRadius:"5px"}}><FontAwesomeIcon icon={faCircleInfo} style={{color: get_platform_bg_color(),}} /> Can't be downloaded!</p>
                                                            </div>}
                                                            {!(downloadSelection || addToSelection || deleteSelection) ? (hideSelection || (faceSearchOn ? faceHide[ele.work_id] : (tab==userFolders ? hideSelectedObj[get_selected_workId(collectionFormattedData, e?.photo_id)] : hideSelectedObj[ele.work_id]))?.some(el =>el.photo_id == e.photo_id)) && (
                                                            <div
                                                                style={{
                                                                position: "absolute",
                                                                top: 0,
                                                                left: 0,
                                                                width: hideSelection ? "100%" : undefined,
                                                                height: hideSelection ? "100%" : undefined,
                                                                backgroundColor: hideSelection ? "rgba(0,0,0,0.5)" : undefined,
                                                                zIndex: hideSelection ? 1 : undefined,
                                                                display: "flex",
                                                                alignItems: "flex-start",
                                                                justifyContent: "flex-start",
                                                                transition: "opacity 0.3s ease",
                                                                }}
                                                            >
                                                                {(faceSearchOn ? faceSelect[ele.work_id] : (tab==userFolders ? selectedImages[get_selected_workId(collectionFormattedData, e?.photo_id)] : selectedImages[ele.work_id]))?.some(el =>el.photo_id == e.photo_id) ? (
                                                                (loadedImages[localIndex] && <img
                                                                    style={{
                                                                    width: "30px",
                                                                    margin: currentDimensions>600 ? "20px" : "12px",
                                                                    pointerEvents: hideSelection ? 'none' : undefined,
                                                                    filter: "drop-shadow(0px 0px 6px #fff)",
                                                                    }}
                                                                    src="https://assets.graphia.in/site_media/webapp/red_eye.png"
                                                                    alt="Selected"
                                                                />)
                                                                ) : (
                                                                (loadedImages[localIndex] && <img
                                                                    style={{
                                                                    width: "30px",
                                                                    margin: currentDimensions>600 ? "20px" : "12px",
                                                                    pointerEvents: hideSelection ? 'none' : undefined,
                                                                    filter: "drop-shadow(0px 0px 6px #fff)",
                                                                    }}
                                                                    src="https://assets.graphia.in/site_media/webapp/white_eye.png"
                                                                    alt="Not Selected"
                                                                />)
                                                                )}
                                                            </div>
                                                            ) : <div
                                                            style={{
                                                            position: "absolute",
                                                            top: 0,
                                                            left: 0,
                                                            width: (downloadSelection || addToSelection || deleteSelection) ? "100%" : undefined,
                                                            height: (downloadSelection || addToSelection || deleteSelection) ? "100%" : undefined,
                                                            backgroundColor: (downloadSelection || addToSelection || deleteSelection) ? "rgba(0,0,0,0.5)" : undefined,
                                                            zIndex: (downloadSelection || addToSelection || deleteSelection) ? 1 : undefined,
                                                            display: "flex",
                                                            alignItems: "flex-start",
                                                            justifyContent: "flex-start",
                                                            transition: "opacity 0.3s ease",
                                                            }}
                                                        >
                                                            {selectImages && (tab==userFolders ? selectImages[get_selected_workId(collectionFormattedData, e?.photo_id)] : selectImages[ele.work_id])?.some(el =>el.photo_id == e.photo_id) ? (
                                                                <FontAwesomeIcon size='lg' icon={faCircleCheck} style={{color: get_platform_action_color(), margin:currentDimensions>600 ? "20px" : "12px",pointerEvents: downloadSelection && 'none', backgroundColor:"white", borderRadius:"50%"}} />
                                                            ) : (
                                                                <FontAwesomeIcon size='lg' icon={hollowCheck} style={{color: "#ffffff", margin:currentDimensions>600 ? "20px" : "12px",pointerEvents: downloadSelection && 'none'}} />
                                                            )}
                                                        </div>}
                                                        </div>
                                                    </a>
                                                )
                                            })}
                                            </Masonry>
                                        </div>
                                    </div>
                                ))
                                )}
                            {( 
                                document.getElementsByClassName('my-masonry-grid_column').length==0
                            ) && (
                                <div className="noPhotoDiv">
                                    <img
                                        style={{height:"90%"}}
                                        className="noPhoto"
                                        src={noPhotoDog}
                                        alt="No Photos Available"
                                    />
                                </div>
                            )}
                            </div>
                        {loading && <div style={{padding:"5%"}}>{globalLoader()}</div>}
                        {nextCollection && !loading && nextCollectionName && !faceSearchOn && <div className="nextCollectionDiv" style={{paddingBottom: getMobileOperatingSystem()=="iOS" && '35%'}}><button className="nextCollection" onClick={handleNextCollection}>{nextCollectionName} <FontAwesomeIcon icon={faArrowRight} style={{color: "#ffffff",}} size="sm"/></button></div>}</div> :
                        <div className="skeleton-grid" style={{display: !(queryParams.get('tab') === photos || queryParams.get('tab') === guestUpload || queryParams.get('tab') === albumSelection || queryParams.get('tab') === userFolders) && 'none'}}>
                            {[...Array(24)].map((_, index) => (
                            <div key={index} className="skeleton-item">
                                <Skeleton height={350} />
                            </div>
                            ))}
                        </div>
                        }
                        {
                            (tab==albums) && albumStatus=='true' && <div>
                                <div style={{position:"absolute", top:"10px", left:"5px", color:"white", zIndex:"100"}} onClick={()=>closeAlbum()}>
                                    <FontAwesomeIcon icon={faXmark} size="xl" style={{color: "#ffffff",cursor:"pointer"}} />
                                </div>
                                {(collection?.data?.length>0) ?
                                <div style={{position: "absolute", width: "100%", top: "0", zIndex: "200"}}><AlbumView data={collection} id={collection_id}/></div> :
                                <div className='overlayLoader' style={{background: "rgba(0,0,0,1)"}}>{globalLoader()}</div>
                            }
                            </div>
                        }</> : <FaceSearch updateFaceSearchResult={updateFaceSearchResult}/>}
                        {shareFaceFilter && shared_key && ((tab==photos && data?.data?.['photo-collection']))  && <div className="shareFirstPage">
                                <div style={{width:"inherit", height:"inherit", backgroundColor:"#FBF7F4", paddingTop:currentDimensions > 600 ? "20vh" : "15vh"}}>
                                    <div style={{display:"flex", justifyContent:"center", flexDirection:"column", alignItems:"center"}}>
                                        <div className="shareFaceDiv">
                                            <img style={{width:"200px", paddingBottom:"30px"}} src="https://assets.graphia.in/site_media/webapp/selfie_emoji.png" alt="faceSearch"/>
                                            <div className="faceFilterDiv" style={{border:`1px solid ${get_platform_bg_color()}`, backgroundColor: get_platform_bg_color(), cursor:"pointer", marginRight: shared_key && '0px'}} onClick={()=>{faceFilterPhoto()}}>
                                                <img className="invert-image" src={selfieIcon} style={{paddingRight: "5px", width: "35px"}}/>
                                                <span style={{color:"white", fontSize:currentDimensions < 600 && "17px"}}>My Photos</span>
                                            </div>
                                            <div style={{paddingTop:"30px", fontSize: currentDimensions > 600 ? "large" : '20px', maxHeight:"80%"}}>Take a selfie to view your photos.</div>
                                        </div>
                                        <div style={{fontSize:currentDimensions > 600 ? "large" : '20px', fontWeight:"900", padding: currentDimensions > 600 ? "20px" : '10px'}}>----- OR -----</div>
                                        <div className="allPhotosShare" onClick={()=>exitFaceSearch()}>View All Photos</div>
                                    </div>
                                </div>
                            </div>}
                        
                        
                    </div>
                    {showGallery && galleryImages && photoIndex >= 0 && (
                        <div  ref={thumbRef}
                        onClick={handlePlayClick}
                        style={{
                            position: "fixed",
                            zIndex: "200",
                            top: "0",
                            left: "0",
                            height: height,
                            width: "100vw",
                        }}
                        className="galleryContainer"
                        >
                            <Carousel
                                ref={carouselRef}
                                index={photoIndex - 1}
                                images={galleryImages}
                                hasCaptions={false}
                                onIndexChange={handleIndexChange}
                                hasThumbnails={false}
                                canAutoPlay={currentDimensions > 600 ? true : false}
                                hasLeftButton={false}
                                shouldLazyLoad={true}
                                hasRightButton={false}
                                onTap={()=>{setLikeViewPopup(false)}}
                                elements={
                                    window.visualViewport.scale<1.1 && <>
                                    <button
                                        style={{
                                        left: 0,
                                        top: 0.44*height,
                                        }}
                                        className="carouselButtons"
                                        aria-label='Go to the slide on the left'
                                        onClick={() => carouselRef.current.goLeft()}
                                    >
                                        <span style={{
                                            fontSize: 'min(30px, 5vw)', padding:"4vw 4vw 4vw 2vw"
                                        }}>
                                            <FontAwesomeIcon icon={faAngleLeft} style={{color: "#ffffff", opacity:"0.5"}} />
                                        </span>
                                    </button>
                                    <button
                                        style={{
                                        right: 0,
                                        top: 0.44*height,
                                        }}
                                        className="carouselButtons"
                                        aria-label='Go to the slide on the right'
                                        onClick={() => carouselRef.current.goRight()}
                                    >
                                        <span
                                        style={{
                                            fontSize: 'min(30px, 5vw)', padding:"4vw 2vw 4vw 4vw"
                                        }}
                                        >
                                            <FontAwesomeIcon icon={faAngleRight} style={{color: "#ffffff", opacity:"0.5"}} />
                                        </span>
                                    </button>
                                    </>
                                }
                            />
                            {
                                loadingHD && <div className='overlayLoader'>{globalLoader()}</div>
                            }
                            {<Popup
                            isOpen={isSingleAddToOpen}
                            onClose={() => {setIsSingleAddToOpen(false); setAddToSelection(false)}}
                            >
                                <div
                                ref={popupRef}
                                style={{
                                    minWidth: currentDimensions > 600 ? "40vw" : "80vw",
                                    padding: "20px",
                                    textAlign: "center",
                                    color: "black"
                                }}
                                >
                                <h3 style={{ margin: "0 0 10px" }}>Add to Album</h3>
                                <ul style={{ listStyle: "none", padding: 0 }}>
                                    {collectionData && collectionData[collection_id]?.album_data?.album_data && Object.entries(collectionData[collection_id].album_data['album_data']).map(([albumKey, myAlbumData]) => (
                                        <li
                                            key={albumKey}
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                padding: "10px",
                                                borderBottom: "1px solid #ddd",
                                                cursor: "pointer",
                                            }}
                                        >
                                        <a href={`${myAlbumData.album_url}`} target="_blank" size='xs' style={{marginRight: '10px'}}><FontAwesomeIcon style={{color: 'gray'}} icon={faExternalLinkAlt} /></a>
                                        |
                                        <label
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                width: "100%",
                                                cursor: "pointer",
                                                marginLeft: "10px",
                                            }}
                                        >
                                            <span>{myAlbumData.title}</span>

                                            {albumData && !albumData[albumKey]?.is_locked ? <input
                                                type="checkbox"
                                                style={{
                                                    cursor: "pointer",
                                                    accentColor: get_platform_bg_color()
                                                }}
                                                checked={checkAlbumKey(galleryImages[photoIndex - 1]?.photo_id, albumKey) || false}
                                                onChange={(event) => {
                                                    const isChecked = event.target.checked;
                                                    updateAlbum(albumKey, isChecked, collection_id, galleryImages[photoIndex - 1]?.photo_id)
                                                }}
                                                disabled={albumData && albumData[albumKey]?.is_locked || isAdmin()}
                                            /> : <span style={{ fontStyle: 'italic', color: 'gray', fontSize: 'medium' }}>Locked</span>}
                                        </label>
                                        </li>
                                    ))}
                                </ul>
                                </div>
                                <button
                                    style={{
                                        marginRight: "2vw",
                                        padding: "10px 20px",
                                        backgroundColor: get_platform_action_color(),
                                        border: "none",
                                        borderRadius: "5px",
                                        color: "white",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {setIsSingleAddToOpen(false); setAddToSelection(false)}}
                                >
                                    Done
                                </button>
                            </Popup>}
                            <div style={{top:currentDimensions < 600 && (0.84*window.innerHeight)}} className="likedStories" onClick={()=>{setLikeViewPopup(!likeViewPopup)}}>
                                {get_photo_like_view_data(galleryImages[photoIndex-1]?.photo_id).firstFewUsers?.length>0 &&
                                <div style={{position: 'relative', display:"flex", alignItems:"center"}}>
                                    {get_photo_like_view_data(galleryImages[photoIndex-1]?.photo_id).firstFewUsers.map((e, index)=>(
                                        <div key={index} style={{display:"flex", alignItems:"center"}}>
                                            <div className="profileDiv" style={{border: `1px solid ${get_platform_bg_color()}`,
                                                                                backgroundColor: get_platform_bg_color(), 
                                                                                fontWeight:"900",
                                                                                position:"absolute",
                                                                                left: index === 1 ? (currentDimensions>=600 ? '20%' : "12%") : "0%",
                                                                                zIndex: index === 1 ? 1 : 0,
                                                                                boxShadow: index === 1 ? "-1px 0px 0px rgba(0, 0, 0, 0.7)" : "none", 
                                                                                transition: "all 0.3s ease-in-out"}}>{e.name_initials}</div>
                                        </div>))
                                    }
                                    <div style={{cursor:"pointer"}}>
                                        <div style={{position:'relative',
                                                     left: get_photo_like_view_data(galleryImages[photoIndex-1]?.photo_id).firstFewUsers.length>1 ? (currentDimensions>=600 ? '45%' : "30%") : (currentDimensions>=600 ? '45%' : "32%"),
                                                     fontSize:"14px",
                                        }}>
                                            <span style={{marginRight:"5px"}}>{get_photo_like_view_data(galleryImages[photoIndex-1]?.photo_id).counts.total_likes>0 ? 'Liked by' : 'Viewed by'}</span>
                                            <span><span style={{fontWeight:'600'}}>{get_photo_like_view_data(galleryImages[photoIndex-1]?.photo_id).firstFewUsers[0]?.first_name}</span> {get_photo_like_view_data(galleryImages[photoIndex-1]?.photo_id)?.user_list.length>1 && (<> and <span style={{ fontWeight: '600' }}>others</span></>)}</span>
                                        </div>
                                    </div>
                                    
                                </div>}
                            </div>
                            {likeViewPopup && <div style={{padding:"15px 0"}} className={currentDimensions < 600 ? "drawer open" : "likeViewPopup"}>
                                <div style={{width:"inherit"}}>
                                    <div style={{fontWeight:"600", display:"flex", justifyContent:"space-between", alignItems:"center", padding: "0 15px 15px 15px"}}>
                                        <div style={{width:"12px"}}></div>
                                        <div>
                                            <div>{get_like_view_string(galleryImages[photoIndex-1]?.photo_id)}</div>
                                        </div>
                                        <div onClick={()=>{setLikeViewPopup(false)}} style={{cursor:"pointer"}}><FontAwesomeIcon icon={faXmark} size="lg"/></div>
                                    </div>
                                    <hr style={{width:"inherit"}}/>
                                    <div className="likeViewContent" style={{padding: "10px 15px", maxHeight: currentDimensions < 600 ? "52vh" : "400px", overflowY:"auto"}}>
                                        {get_photo_like_view_data(galleryImages[photoIndex-1]?.photo_id)?.user_list?.map((e, index)=>(
                                            <div key={index} style={{padding:"10px", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                                                <div style={{display:"flex", alignItems:"center"}}>
                                                    <div className="profileDiv" style={{border: `1px solid ${get_platform_bg_color()}`,backgroundColor: get_platform_bg_color(), fontWeight:"900", minWidth:"30px", minHeight:"30px"}}>{e.name_initials}</div>
                                                    <div style={{padding: "0 10px", letterSpacing:"0.03rem"}}>{e.first_name}</div>
                                                </div>
                                                {e.is_liked && <div style={{display:"flex", alignItems:"center"}}><img style={{width:"20px", marginRight:"5px"}} src={redHeartIcon} alt="like"/></div>}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>}
                            {window.visualViewport.scale<1.4 && <div onClick={()=>setLikeViewPopup(false)} style={{top:currentDimensions < 600 && (0.90*height)}} className={currentDimensions > 600 ? 'topControls' : `${faceSearchOn ? ((get_can_add_to(galleryImages[photoIndex-1].photo_id, faceSearchData) && get_is_album_required(galleryImages[photoIndex-1].photo_id, faceSearchData) && tab!==guestUpload) ? 'bottomControls' : 'centerControls') : ((get_can_add_to(galleryImages[photoIndex - 1]?.photo_id, collection) && get_is_album_required(galleryImages[photoIndex-1].photo_id, collection) && tab!==guestUpload) ? 'bottomControls' : 'centerControls')}`}>
                                {(faceSearchOn ? (get_can_add_to(galleryImages[photoIndex-1].photo_id, faceSearchData) && get_is_album_required(galleryImages[photoIndex-1].photo_id, faceSearchData)) : (get_can_add_to(galleryImages[photoIndex - 1]?.photo_id, collection) && get_is_album_required(galleryImages[photoIndex-1].photo_id, collection))) && <><div className={`${faceSearchOn ? ((get_global_can_add_to(faceSearchData) && get_global_is_album_required(faceSearchData)) && currentDimensions < 600 ? 'leftCenterControls' : 'topCenterControls') : ((get_can_add_to(galleryImages[photoIndex - 1]?.photo_id, collection) && get_is_album_required(galleryImages[photoIndex-1].photo_id, collection)) && currentDimensions < 600 ? 'leftCenterControls' : 'topCenterControls')}`}>
                                    <div style={{display: tab==guestUpload && 'none'}} className={
                                            faceSearchOn
                                                ? (get_is_downloadable(galleryImages[photoIndex-1].photo_id, faceSearchData) ? (hdUrl ? 'activeHD' : 'hdButton') : 'displayNone')
                                                : (get_is_downloadable(galleryImages[photoIndex-1].photo_id, collection) ? (hdUrl ? 'activeHD' : 'hdButton') : 'displayNone')
                                        } onClick={switchHD}>
                                        <p>HD</p>
                                    </div>
                                    {!selection && (faceSearchOn ? get_can_delete(galleryImages[photoIndex - 1]?.photo_id, faceSearchData) : get_can_delete(galleryImages[photoIndex - 1]?.photo_id, collection) || (collection?.album_delete && !albumLocked)) && <div className="deleteDiv" style={{cursor:"pointer", marginLeft: currentDimensions>600 && (currentDimensions>900 ? '20px' : "15px")}} onClick={()=>handleDeletePhoto(galleryImages[photoIndex-1]?.photo_id)}><img style={{width: "22px"}} className="coloredImage" src={deleteIcon} alt='delete'/></div>} 
                                    {<div className={
                                        faceSearchOn 
                                            ? (get_can_make_private(galleryImages[photoIndex-1].photo_id, faceSearchData) ? 'galleryHide' : 'displayNone')
                                            : (get_can_make_private(galleryImages[photoIndex-1].photo_id, collection) ? 'galleryHide' : 'displayNone')
                                    } onClick={()=>handlePhotoHide(galleryImages[photoIndex-1]?.photo_id)}>
                                        {faceSearchOn ? (
                                            Object.keys(faceHide)?.some((key) =>
                                                faceHide[key]?.some(e => e.photo_id === galleryImages[photoIndex - 1]?.photo_id)
                                            ) ? (
                                                <img style={{ width: "24px" }} src={redHideIcon} />
                                            ) : (
                                                <img style={{ width: "24px" }} src={whiteHideIcon} />
                                            )
                                            ) : (
                                            Object.keys(hideSelectedObj)?.some((key) =>
                                                hideSelectedObj[key].some(e => e.photo_id === galleryImages[photoIndex - 1]?.photo_id)
                                            ) ? (
                                                <img style={{ width: "24px" }} src={redHideIcon} />
                                            ) : (
                                                <img style={{ width: "24px" }} src={whiteHideIcon} />
                                            )
                                        )}
                                    </div>}
                                </div>
                                {(!faceSearchOn ? (get_can_add_to(galleryImages[photoIndex - 1]?.photo_id, collection) && get_is_album_required(galleryImages[photoIndex-1].photo_id, collection)) : (get_can_add_to(galleryImages[photoIndex - 1]?.photo_id, faceSearchData) && get_is_album_required(galleryImages[photoIndex - 1]?.photo_id, faceSearchData))) && (get_can_add_to(galleryImages[photoIndex - 1]?.photo_id, collection) && get_is_album_required(galleryImages[photoIndex-1].photo_id, collection)) && <div className={
                                        faceSearchOn
                                            ? (!shared_key ? 'galleryAddto' : "displayNone")
                                            : (!shared_key ? 'galleryAddto' : 'displayNone')
                                } onClick={toggleSingleAddToPopup}>
                                    <FontAwesomeIcon icon={faPlus} style={{color: "#000000"}}/> Add to
                                </div>}                                
                                <div className={`${faceSearchOn ? ((get_can_add_to(galleryImages[photoIndex-1].photo_id, faceSearchData) && get_is_album_required(galleryImages[photoIndex-1].photo_id, faceSearchData)) && currentDimensions < 600 ? 'rightCenterControls' : 'topCenterControls') : ((get_can_add_to(galleryImages[photoIndex - 1]?.photo_id, collection) && get_is_album_required(galleryImages[photoIndex-1].photo_id, collection)) && currentDimensions < 600 ? 'rightCenterControls' : 'topCenterControls')}`}>
                                    <div
                                        className={
                                            faceSearchOn
                                                ? (get_is_downloadable(galleryImages[photoIndex-1].photo_id, faceSearchData) ? 'downloadButton' : 'displayNone')
                                                : (get_is_downloadable(galleryImages[photoIndex-1].photo_id, collection) ? 'downloadButton' : 'displayNone')
                                        }
                                        onClick={()=>downloadImage(this)}
                                    >
                                        <img style={{height: "20px"}} src={downloadIcon} className="coloredImage" alt="download"/>
                                    </div>
                                    <div className="likeDiv" onClick={()=>handleLikeViewPhoto(galleryImages[photoIndex-1]?.photo_id, likePhotos)}>
                                        <img className={get_user_liked_photos(likeData, galleryImages[photoIndex-1]?.photo_id) ? "" : "invert-image"} style={{width: "22px"}} src={get_user_liked_photos(likeData, galleryImages[photoIndex-1]?.photo_id) ? redHeartIcon : heartIcon}/>
                                    </div>
                                </div></>}
                                {(faceSearchOn ? (!get_can_add_to(galleryImages[photoIndex-1]?.photo_id, faceSearchData) && !get_is_album_required(galleryImages[photoIndex-1]?.photo_id, faceSearchData)) : (!get_can_add_to(galleryImages[photoIndex - 1]?.photo_id, collection) && !get_is_album_required(galleryImages[photoIndex-1]?.photo_id, collection))) && <>
                                    {!selection && (faceSearchOn ? get_can_delete(galleryImages[photoIndex - 1]?.photo_id, faceSearchData) : get_can_delete(galleryImages[photoIndex - 1]?.photo_id, collection) || collection?.album_delete) && <div className="deleteDiv" style={{cursor:"pointer"}} onClick={()=>handleDeletePhoto(galleryImages[photoIndex-1]?.photo_id)}><img style={{width:"22px"}} className="coloredImage" src={deleteIcon} alt='delete'/></div>}
                                    <div style={{display: tab==guestUpload && 'none'}} className={
                                            faceSearchOn
                                                ? (get_is_downloadable(galleryImages[photoIndex-1]?.photo_id, faceSearchData) ? (hdUrl ? 'activeHD' : 'hdButton') : 'displayNone')
                                                : (get_is_downloadable(galleryImages[photoIndex-1]?.photo_id, collection) ? (hdUrl ? 'activeHD' : 'hdButton') : 'displayNone')
                                        } onClick={switchHD}>
                                        <p>HD</p>
                                    </div>
                                    {<div className={
                                        faceSearchOn 
                                            ? (get_can_make_private(galleryImages[photoIndex-1]?.photo_id, faceSearchData) ? 'galleryHide' : 'displayNone')
                                            : (get_can_make_private(galleryImages[photoIndex-1]?.photo_id, collection) ? 'galleryHide' : 'displayNone')
                                    } onClick={()=>handlePhotoHide(galleryImages[photoIndex-1]?.photo_id)}>
                                        {faceSearchOn ? (
                                            Object.keys(faceHide)?.some((key) =>
                                                faceHide[key]?.some(e => e.photo_id === galleryImages[photoIndex - 1]?.photo_id)
                                            ) ? (
                                                <img style={{ width: "24px" }} src={redHideIcon} />
                                            ) : (
                                                <img style={{ width: "24px" }} src={whiteHideIcon} />
                                            )
                                            ) : (
                                            Object.keys(hideSelectedObj)?.some((key) =>
                                                hideSelectedObj[key]?.some(e => e.photo_id === galleryImages[photoIndex - 1]?.photo_id)
                                            ) ? (
                                                <img style={{ width: "24px" }} src={redHideIcon} />
                                            ) : (
                                                <img style={{ width: "24px" }} src={whiteHideIcon} />
                                            )
                                        )}
                                    </div>}
                                    {faceSearchOn && (get_can_add_to(galleryImages[photoIndex - 1]?.photo_id, faceSearchData) && get_is_album_required(galleryImages[photoIndex - 1]?.photo_id, faceSearchData)) && <div className={
                                            faceSearchOn
                                                ? (!shared_key ? 'galleryAddto' : "displayNone")
                                                : (!shared_key ? 'galleryAddto' : 'displayNone')
                                    } onClick={toggleSingleAddToPopup}>
                                        <FontAwesomeIcon icon={faPlus} style={{color: "#000000"}}/> Add to
                                    </div>}
                                    <div
                                        className={
                                            faceSearchOn
                                                ? (get_is_downloadable(galleryImages[photoIndex-1]?.photo_id, faceSearchData) ? 'downloadButton' : 'displayNone')
                                                : (get_is_downloadable(galleryImages[photoIndex-1]?.photo_id, collection) ? 'downloadButton' : 'displayNone')
                                        }
                                        onClick={()=>downloadImage(this)}
                                    >
                                        <img style={{height: "20px"}} src={downloadIcon} className="coloredImage" alt="download"/>
                                    </div>
                                    <div className="likeDiv" onClick={()=>handleLikeViewPhoto(galleryImages[photoIndex-1]?.photo_id, likePhotos)}>
                                        <img className={get_user_liked_photos(likeData, galleryImages[photoIndex-1]?.photo_id) ? "" : "invert-image"} style={{width: "22px"}} src={get_user_liked_photos(likeData, galleryImages[photoIndex-1]?.photo_id) ? redHeartIcon : heartIcon}/>
                                    </div>
                                </>}
                            </div>}
                            <div
                            style={{ position: "absolute", top: "11px", left: "20px" }}
                            onClick={closeGalleryHandler}
                                >
                                    <FontAwesomeIcon
                                    icon={faArrowLeft}
                                    size="xl"
                                    className="closeEffect"
                                    />
                            </div>
                            <div className="photoName">
                                <p>{galleryImages[photoIndex-1]?.alt.length>15 ? galleryImages[photoIndex-1]?.alt.slice(0, 15)+'...' : galleryImages[photoIndex-1]?.alt}</p>
                            </div>
                        </div>
                    )}
                    <div id="hidePopupDiv">
                        <div id="hidePhotoPopup">
                            <h1><span id="popupTitle">Hide</span> this Photo?</h1>
                            <h2 id="popupDesc">Hidden photos won't be visible to everyone(except Admin)</h2>
                            <h3>Note</h3>
                            <li>Only Admin can hide/unhide photos</li>
                            <div id="hideButtonDiv">
                                <button id="hideButtonGallery" onClick={()=>handleHideImage(galleryImages[photoIndex-1]?.photo_id)} style={{backgroundColor: get_platform_bg_color()}}>Hide</button>
                                <button id="cancelHideButton" onClick={cancelHide}>Cancel</button>
                            </div>
                        </div>
                    </div>
                    <div id="mediaHidePopup">
                        <div id="hideMediaPopup">
                            <h1><span id="popupMediaTitle">Hide</span> this Media?</h1>
                            <h2 id="popupMediaDesc">Hidden media won't be visible to everyone(except Admin)</h2>
                            <h3>Note</h3>
                            <li>Only Admin can hide/unhide media</li>
                            <div id="hideButtonDiv">
                                <button id="hideButtonMedia" style={{backgroundColor: get_platform_bg_color()}}>Hide</button>
                                <button id="cancelHideButton" onClick={cancelMediaHide}>Cancel</button>
                            </div>
                        </div>
                    </div>
                    <div id="grideHidePopup">
                        <div id="gridHidePopup">
                            <h3>Hide Photos</h3>
                            <li>Click on <span style={{verticalAlign:"sub"}}><img style={{width:"20px", display:"inline-block", verticalAlign:"center"}} src={hideIcon}/></span> to hide/show photos</li>
                            <li>Hidden photos won't be visible to everyone(except Admin)</li>
                            <li>Only Admin can hide/unhide photos</li>
                            <div id="grideHideButtonDiv">
                                <button style={{backgroundColor: get_platform_action_color(), 
                                                color: "#ffffff", 
                                                border: `1px solid ${get_platform_action_color()}`, 
                                                padding:"5px 12px",
                                                borderRadius:"5px"}} onClick={okayHide}>Okay</button>
                            </div>
                        </div>
                    </div>
                    {showToast && <Toast message={toastMessage} duration={toastMessage?.hide_in} onClose={() => setShowToast(false)}/>}
                    {faceFilter && <div className="filterBackdrop">
                            <div className={currentDimensions < 600 ? "drawer open" : "facePopup"}>
                                <div>
                                    <p style={{position:"absolute", top:"10px", right:"20px", cursor:"pointer"}} onClick={closeFacePopup}><FontAwesomeIcon icon={faXmark} size="lg"/></p>
                                    <div style={{fontWeight :"900", paddingBottom:"30px"}}>Past face searches</div>
                                    <div style={{display:"flex", alignItems:"center", width:"100%", justifyContent:"space-around"}}>
                                    {isScrollable && <p onClick={scrollLeft}><FontAwesomeIcon icon={faAngleLeft} size="lg" style={{color: "#000000",}} /></p>}
                                    {!loading ? (
                                    pastFaceSearches?.length > 0 && (
                                        <div id='pastFacesDiv' style={{justifyContent: pastFaceSearches?.length==1 && 'center'}} ref={scrollContainerRef}>
                                            {pastFaceSearches.map((ele, index) => (
                                                <p key={index} style={{margin: `10px ${pastMargin}px`}} className={pastFaceSearches.length == 1 ? "singleFacesPara" : "pastFacesPara"} onClick={() => indiFaceData(ele._id)}>
                                                    <img
                                                    style={{borderRadius:"50%", height: currentDimensions <600 ? "80px" : "100px"}}
                                                    src={croppedImages[index]}
                                                    alt=""
                                                    />
                                                </p>
                                            ))}
                                        </div>
                                    )
                                    ) : (
                                    <div style={{ paddingTop: "10px" }}>{globalLoader('lg')}</div>
                                    )}
                                    {isScrollable && <p onClick={scrollRight}><FontAwesomeIcon icon={faAngleRight} size="lg" style={{color: "#000000",}} /></p>}
                                    </div>
                                    <div style={{padding:"30px 50px"}}>------or------</div>
                                    <div><button style={{background:get_platform_action_color(), color:"white", borderRadius:"5px", padding:"5px 12px"}} onClick={()=>faceSearch()}>Take a selfie</button></div>
                                </div>
                            </div>
                        </div>}
                    {tab==guestUpload && collectionData && <GuestUpload data={collectionData} bookingData={getBookingData} />}
                    {<div style={{display: !shareLinkPopup && 'none'}}><Popup 
                        isOpen={true}
                        onClose={() => {
                        setShareLinkPopup(false);
                        setNickname("");
                        }}>
                        <div style={{ textAlign: "center", color:"black", display: "flex", flexDirection: "column", alignItems: "center" }} className="popupWidth">
                            <div style={{ fontSize: "25px", paddingTop: "0.2rem", fontWeight:"900", marginBottom: "0.5rem" }}>Share</div> 
                            <div id="shareNote"></div>    
                            <div onClick={()=>copyLinkUrl(shareLinkData?.share_url)} className="cpyAlbum">
                                <FontAwesomeIcon
                                    icon={faCopy}
                                    style={{ color: "#ffffff" }}
                                />
                            Copy Link
                            </div>
                            <Link style={{display:"grid", justifyItems:"center"}} target='_blank' to={`${getWhatsappShareUrl(shareLinkData?.share_msg)}`}>
                            <button className="shareWp">
                                <FontAwesomeIcon
                                icon={faWhatsapp}
                                style={{ color: "#ffffff" }}
                                />
                                Share
                            </button>
                            </Link>    


                            {nickname.trim() != "" &&   <div style={{fontSize: '19px', fontWeight: "600", marginTop: "10px"}}><>{nickname}  
                                                    <span>
                                                    <FontAwesomeIcon icon={faPenToSquare} style={{marginLeft: "5px", color: "black", cursor: "pointer"}} size='sm' onClick={clickEditNameIcon} />
                                                    </span></>  
                                                </div> 
                            }

                                                <div style={{display:  nickname.trim() !== "" ? "none" : "flex", flexDirection: "column", alignItems: "center",gap: "0.2rem" }}>
                                                    <div style={{padding:"10px 0", fontWeight:"500", textAlign:"center", marginTop: "10px", maxWidth: "18rem"}}>Add a link title: </div>
                                                    <div style={{display: "flex", alignItems: "center", gap: "0.35rem"}}>
                                                    <input
                                                        ref={nicknameInput}
                                                        type="text"
                                                        className="form-input"
                                                        style={{
                                                        width: "12rem",
                                                        background: "whitesmoke",
                                                        padding: "7px 9px",
                                                        border: "2px solid #EEEEEE",
                                                        borderRadius: "5px",
                                                        fontStyle: "italic"
                                                        }}
                                                        maxLength={25}
                                                        placeholder="e.g: DJ Night"
                                                        onInput={(e) => {
                                                            const isEmpty = e.target.value.trim() === "";
                                                            document.getElementById("nickname-save-btn").disabled = isEmpty;
                                                            document.getElementById("nickname-save-btn").style.opacity = isEmpty ? "0.5" : "1";
                                                            document.getElementById("nickname-save-btn").style.cursor = isEmpty ? "not-allowed" : "pointer";
                                                        }}
                                                    />
                                                        <button id="nickname-save-btn" style={{color: 'white', background: get_platform_bg_color(), padding:"5px 8px", borderRadius:"5px", border: `1px solid ${get_platform_bg_color()}`, cursor: "not-allowed", opacity: "0.5"}} onClick={addNickname} >
                                                            <span style={{paddingLeft:"5px"}}>Save</span>
                                                        </button>
                                                    </div>
                                                </div>

                        </div>
                    </Popup></div>}
                    {downloadPopup && <Popup
                        isOpen={true}
                        onClose={() => {
                        setDownloadPopup(false);
                    }}>
                        <div style={{textAlign:"center", fontWeight:"900", fontSize:"25px", minWidth:"20vw"}}>Download</div>
                        <div style={{justifyItems:"center", width:"100%", display:"grid"}} onClick={()=>{setDownloadPopup(false);getSelectionData(download)}}><button style={{background: get_platform_bg_color()}} className="downMethod"><FontAwesomeIcon icon={hollowCheck} style={{paddingRight:"5px", color:"#ffffff"}}/><p>Selectively</p></button></div>
                        <div style={{justifyItems:"center", width:"100%", display:"grid"}} onClick={()=>{setDownloadPopup(false);if(!faceSearchOn && tab!==albumSelection && tab!==userFolders){downloadCollection()}else{downloadFaceSearch()}}}><button style={{background: get_platform_action_color()}} className="downMethod"><img className="invert-image" style={{height: "20px", paddingRight:"5px"}} src={downloadIcon} alt="download"/><p>{(faceSearchOn || tab==guestUpload) ? <span>All Photos</span>  : collection?.title}</p></button></div>
                    </Popup>}
                    {qualityVideo && <Popup
                        isOpen={true}
                        onClose={() => {
                        setQualityVideo(false);
                    }}>
                        <div style={{margin:"0 20px", justifyItems:"center", display:"grid"}}>
                            <div style={{textAlign:"center", fontWeight:"600", fontSize:"23px", padding:"5px"}}>Quality</div>
                            {videoDetails?.map((e) => {
                                return <div key={e.quality} className="qualityRadio">
                                    <label style={{display:"flex", alignItems:"center", justifyContent:"space-between", minWidth:"170px"}}>
                                        <p>{e.name}</p>
                                        <input
                                        type="radio"
                                        name="video"
                                        value={e.quality}
                                        checked={e.quality==currentQuality}
                                        onChange={() => {setCurrentQualityUrl(e.url); setCurrentQuality(e.quality)}}
                                        style={{accentColor:get_platform_action_color()}}
                                        />
                                    </label>
                                </div>;
                            })}
                            <div style={{padding:"5px 10px", borderRadius:"5px", color:"white", background: get_platform_action_color(), width:"fit-content", margin:"5px", cursor:"pointer"}} onClick={downloadVideo}>Download</div>
                        </div>
                    </Popup>}
                    {data?.bottom_float_bar && !workIdError && collection && document.getElementsByClassName('shareFirstPage').length==0 && <FloatingBar data={data?.bottom_float_bar}/>}
                    {shareSelective && <div className="shareOverlay">
                        <div className={`shareSelectiveDiv ${currentDimensions<600 ? 'drawer open' : ""}`}>
                            <div style={{fontSize:"larger", fontWeight:"900", width:"100%", display:"flex", justifyContent:"space-between", padding:"10px 5%"}}>
                                <div style={{width:"16px"}}></div>
                                <div>Share Selectively</div>
                                <div><FontAwesomeIcon icon={faXmark} size="lg" onClick={confirShareLost} style={{cursor:"pointer"}}/></div>
                            </div>
                            <div style={{padding:"10px", display:"flex", justifyContent:"space-evenly", width:"100%", color:"white", background: get_platform_bg_color()}}>
                            {data?.data && Object.keys(data?.data).map((key, index) => (
                                <React.Fragment key={index}>
                                    {key === 'photo-collection' && <div key={index}>
                                            <div onClick={()=>switchShareTabs(photos)} style={{cursor:"pointer", display:"flex", alignItems:"center",minWidth:"max-content", padding:"10px 0", margin: currentDimensions > 600 ? "0 20px" : "0 5px" , borderBottom: shareTab==photos && '3px solid white', fontSize: currentDimensions<600 && '13px'}}>
                                                <div><img style={{height:"20px", paddingRight:"5px"}} className="invert-image" src={imageIcon}/></div>
                                                <div><span style={{paddingRight:"5px"}}>Photos</span></div>
                                                {shareSelectList[photos]?.length>0 && <div>{`(${shareSelectList[photos]?.length})`}</div>}
                                            </div>
                                        </div>}  
                                    {key === 'video' && <div key={index}>
                                            <div onClick={()=>switchShareTabs(videos)} style={{cursor:"pointer", display:"flex", alignItems:"center",minWidth:"max-content", padding:"10px 0", margin: currentDimensions > 600 ? "0 20px" : "0 5px" , borderBottom: shareTab==videos && '3px solid white', fontSize: currentDimensions<600 && '13px'}}>
                                                <div><img style={{height:"20px", paddingRight:"5px"}} className="invert-image" src={videoIcon}/></div>
                                                <div><span style={{paddingRight:"5px"}}>Videos</span></div>
                                                {shareSelectList[videos]?.length>0 && <div>{`(${shareSelectList[videos]?.length})`}</div>}
                                            </div>
                                        </div>}
                                    {key === 'album' && <div key={index}>
                                            <div onClick={()=>switchShareTabs(albums)} style={{cursor:"pointer", display:"flex", alignItems:"center",minWidth:"max-content", padding:"10px 0", margin: currentDimensions > 600 ? "0 20px" : "0 5px" , borderBottom: shareTab==albums && '3px solid white', fontSize: currentDimensions<600 && '13px'}}>
                                                <div><img style={{height:"20px", paddingRight:"5px"}} className="invert-image" src={albumIcon}/></div>
                                                <div><span style={{paddingRight:"5px"}}>Albums</span></div>
                                                {shareSelectList[albums]?.length > 0 && <div>{`(${shareSelectList[albums]?.length})`}</div>}
                                            </div>
                                        </div>}
                                </React.Fragment>
                            ))}
                            </div>
                            <div style={{textAlign:"right", padding:"0 24px", width:currentDimensions>600 ? "100%" : "90%", display:"flex", justifyContent:"space-between", padding: "10px 5%"}}>
                                {shareTab==photos && <div>{checkPlurality(getShareTab(shareTab),'Collection')}</div>}
                                {shareTab==videos && <div>{checkPlurality(getShareTab(shareTab),'Video')}</div>}
                                {shareTab==albums && <div>{checkPlurality(getShareTab(shareTab),'Album')}</div>}
                                {getUnhiddenShareTab(shareTab).length == (shareSelectList[shareTab]?.length)
                                    ? <div style={{display:"flex", alignItems:"center"}} onClick={()=>shareSelectAllCollection(shareTab, [])}><FontAwesomeIcon size="lg" icon={faCircleCheck} style={{color: get_platform_action_color(), backgroundColor:"white", borderRadius:"50%", paddingRight:"5px"}} /><p>Select all</p></div>
                                    : <div style={{display:"flex", alignItems:"center"}} onClick={()=>shareSelectAllCollection(shareTab, getShareTab(shareTab))}><FontAwesomeIcon size="lg" icon={hollowCheck} style={{color: get_platform_action_color(), backgroundColor:"white", borderRadius:"50%", paddingRight:"5px"}} /><p>Select all</p></div>
                                }
                            </div>
                            <div className="shareGrid">{getShareTab(shareTab).map((each) => (
                                <div onClick={()=>shareSelectMedia(shareTab, each.work_id)} style={{margin:"10px", position:"relative", maxWidth:currentDimensions>600 ? "200px" : "150px", minWidth:currentDimensions>600 ? "200px" : "150px", justifyItems:"center", display:"grid"}} key={each.work_id}>
                                    <div className="shareCards">
                                        <img style={{borderRadius:"10px", height:"100%", width:"100%", objectFit:"cover"}} src={shareTab==videos ? each.thumb_url : each.cover_image}/>
                                        <div style={{position:"absolute", width:"100%", height:"100%", background:"rgba(0,0,0,0.5)", top:"0", borderRadius:"10px"}}></div>
                                    </div>
                                    <div style={{padding:"5px 0"}}>{each.title}</div>
                                    {!hiddenWork[each.work_id] ?
                                    <div style={{position:"absolute", top:"10px", left:"20px", cursor:"pointer"}}>
                                        <div>{shareSelectList && shareSelectList[shareTab]?.includes((each.work_id).toString()) 
                                            ? <FontAwesomeIcon size='lg' icon={faCircleCheck} style={{color: get_platform_action_color(), backgroundColor:"white", borderRadius:"50%"}} />
                                            : <FontAwesomeIcon size='lg' icon={hollowCheck} style={{color: get_platform_action_color(), backgroundColor:"white", borderRadius:"50%"}} />}
                                        </div>
                                    </div> : <div className="hiddenShareCard">
                                        <div className="hiddenShareCardText">Hidden media cannot be shared.</div> <div className="unhideBtn" onClick={()=>hideSelected(each.work_id, true)}>Unhide</div>
                                    </div>}
                                </div>
                            ))}</div>
                            <div style={{textAlign:"right", padding:"15px 24px"}}>
                                <button onClick={()=>{!isAdmin() && shareSelection()}} className="shareSelectiveDone" style={{background: get_platform_action_color()}}>{`Share(${Object.values(shareSelectList).flat().length})`}</button>
                            </div>
                        </div>
                    </div>}
                    {deletePopup && <div className="infoDiv" style={{zIndex:"201"}}>
                        <div style={{background:"white", padding:"20px", borderRadius:"5px"}}>
                            <div style={{padding:"7px 0 10px 0"}}>Photos will be permanently deleted from this album!</div>
                            <div style={{textAlign:"right", padding:"7px 0 10px 0"}}>
                                <button style={{padding:"2px 5px", borderRadius:"5px", border:"1px solid black",marginRight:"10px"}} onClick={cancelSelection}>Cancel</button>
                                <button style={{padding:"2px 5px", borderRadius:"5px", background:get_platform_action_color(), color:"white", border: `1px solid ${get_platform_action_color()}`}} onClick={()=>{setDeleteConfirm(true);setDeletePopup(false)}}>Delete</button>
                            </div>
                        </div>
                    </div>}
                    {tab==guestUpload && collection && collection?.hasOwnProperty('user_agreement') && !collection?.user_agreement && <div className="tnCOverlay">
                        <div className="tnCPopup">
                            <div className="tnCText"></div>
                            <div style={{display:"flex", alignItems:"center", justifyContent:"center", padding:"20px 10px"}}>
                                <button style={{background:get_platform_action_color(), color:"white", borderRadius:"10px", padding:"5px 8px"}} onClick={tncApproved}>I agree</button>
                            </div>
                        </div>
                    </div>}
                </>
             : <>
                {faceOnly ? <>
                    <Navbar location={"/workDetails"} title={``}/>
                    {updateLoader && <div className="overlayLoader">{globalLoader()}</div>}
                    <div>
                        <FaceSearch updateFaceSearchResult={updateFaceSearchResult}/>
                        {showToast && <Toast message={toastMessage} duration={3000} onClose={() => setShowToast(false)}/>}
                        {pastFaceSearches?.length > 0 && <p style={{padding:currentDimensions>600 ? "15px 0" : "5px 0"}}>Past face searches!</p>}
                        <div style={{display:"flex", alignItems:"center", width:"100%", justifyContent:"center"}}>
                            <div style={{display:"flex", justifyContent:"center", width:"100%", alignItems:"center"}}>
                                {isScrollable && <p onClick={scrollLeft}><FontAwesomeIcon icon={faAngleLeft} size="lg" style={{color: "#000000",}} /></p>}
                                {Array.isArray(pastFaceSearches) ? (
                                pastFaceSearches?.length > 0 && (
                                    <div id='pastFacesDiv'  style={{maxWidth: currentDimensions > 600 ? "60%" : "90%", width:"auto", justifyContent: pastFaceSearches?.length==1 && 'center'}} ref={scrollContainerRef}>
                                    {pastFaceSearches.map((ele, index) => (
                                    <p key={index} style={{margin: `10px ${pastMargin}`}} className="pastFacesPara" onClick={() => indiFaceData(ele._id)}>
                                        <img
                                        style={{borderRadius:"50%", height: currentDimensions <600 ? "80px" : "100px"}}
                                        src={croppedImages[index]}
                                        />
                                    </p>
                                    ))}
                                    </div>
                                )
                                ) : (
                                <div style={{ paddingTop: "30px" }}>{globalLoader('lg')}</div>
                                )}
                                {isScrollable && <p onClick={scrollRight}><FontAwesomeIcon icon={faAngleRight} size="lg" style={{color: "#000000",}} /></p>}
                            </div>
                        </div>
                    </div>
                </>  : !workIdError && <SkeletonLoader />}
            </>}
            {workIdError && <CustomeError errMessage={workIdErrorMessage} />}
        </>
    )
}

export default WorkDetail
